import React, { useEffect, useState, useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import styled from '@emotion/styled';
import { Container, Link, Text } from 'basis';

function SimplifiedFooter({ product }) {
  const [analytics] = useContext(AnalyticsContext);

  const [linkUrl, setLinkUrl] = useState('');
  useEffect(() => {
    if (product === 'gem') {
      setLinkUrl('https://www.gemfinance.co.nz');
    } else {
      setLinkUrl('https://www.latitudefinancial.com.au');
    }
  }, []);

  const IconContainer = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  `;

  const Icon = () => (
    <svg height="30" viewBox="0 0 30 30" width="30">
      <g>
        <circle cx="15" cy="15" fill="#e9e8e7" r="15" />
        <path
          d="m19.5 11.3333333h-.75v-1.52380949c0-2.10285714-1.68-3.80952381-3.75-3.80952381s-3.75 1.70666667-3.75 3.80952381v1.52380949h-.75c-.825 0-1.5.6857143-1.5 1.5238096v7.6190476c0 .8380952.675 1.5238095 1.5 1.5238095h9c.825 0 1.5-.6857143 1.5-1.5238095v-7.6190476c0-.8380953-.675-1.5238096-1.5-1.5238096zm-2.175 0h-4.65v-1.52380949c0-1.30285714 1.0425-2.36190476 2.325-2.36190476s2.325 1.05904762 2.325 2.36190476z"
          fill="#65686b"
        />
      </g>
    </svg>
  );

  const LinkContainer = styled.div`
    a {
      margin: 0 6px 6px;
      display: inline-block;
      border: none;
    }
  `;

  return (
    <Container bg="secondary.lightBlue.t15" padding="4">
      <Container hasBreakpointWidth>
        <Container margin="0 0 4 0">
          <IconContainer>
            <Icon />
          </IconContainer>
        </Container>

        <Container margin="0 0 4 0">
          <LinkContainer>
            <Text textStyle="body2" align="center">
              <Link
                href={`${linkUrl}/privacy/`}
                newTab
                onClick={() => {
                  trackEvent(analytics, {
                    category: 'text-link',
                    action: 'internal-link',
                    location: 'footer',
                    label: 'Privacy and Credit Reporting Policy'
                  });
                }}
                testId = 'footer-privacy'
              >
                Privacy and Credit Reporting Policy
              </Link>
              <Link
                href={`${linkUrl}/terms-and-conditions/`}
                newTab
                onClick={() => {
                  trackEvent(analytics, {
                    category: 'text-link',
                    action: 'internal-link',
                    location: 'footer',
                    label: 'Website Terms and Conditions'
                  });
                }}
                testId = 'footer-terms'
              >
                Website Terms and Conditions
              </Link>
              <Link
                href={`${linkUrl}/security/`}
                newTab
                onClick={() => {
                  trackEvent(analytics, {
                    category: 'text-link',
                    action: 'internal-link',
                    location: 'footer',
                    label: 'Security'
                  });
                }}
                testId = 'footer-security'
              >
                Security
              </Link>
            </Text>
          </LinkContainer>
        </Container>
        <Text textStyle="body2" align="center">
          {product === 'gem'
            ? 'Gem Personal Loans are provided by Latitude Financial Services Limited'
            : 'Credit provided by Latitude Personal Finance Pty Ltd ABN 54 008 443 810. Australian Credit Licence Number 392163'}
        </Text>
      </Container>
    </Container>
  );
}

export { SimplifiedFooter };
