import React from 'react';
import styled from 'styled-components';
import { Text } from 'basis';
import { BREAKPOINT } from '@latitude/core/utils/constants';

export default props => {
  const Outer = styled.div`
    padding: 0 15px;
    @media (min-width: ${BREAKPOINT.MD}) {
      padding: 0;
    }
  `;

  return (
    props.children || (
      <Outer>
        <Text textStyle="heading3" align="center" margin="4 0">
          {props.title}
        </Text>
        <Text>{props.text}</Text>
      </Outer>
    )
  );
};
