import { useEffect, useRef, useCallback } from 'react';

const startTimeout = (callback, time) => setTimeout(() => callback(true), time);

const resetTimeout = (timeout, callback, time) => {
  clearTimeout(timeout);
  return startTimeout(callback, time);
};

export function useSessionTimeout(
  callback,
  isBroker,
  events = ['load', 'click', 'scroll', 'keypress'],
) {
  // non-broker 5 minutes(300000 milli-seconds) broker 25 minutes (1500000 milli-seconds)
  const WARNING_TIME = isBroker ? 1500000 : 300000;
  // Don't use state as changing value would cause re-render
  const logoutTimeout = useRef(null);

  const abort = useCallback(() => {
    logoutTimeout.current = resetTimeout(
      logoutTimeout.current,
      callback,
      WARNING_TIME,
    );
  }, [WARNING_TIME, callback]);

  if (typeof window !== 'undefined') {
    window.onbeforeunload = null;
  }

  useEffect(() => {
    if (!WARNING_TIME) {
      return () => {};
    }

    // setup initial page timeouts and event listeners to reset timeouts
    logoutTimeout.current = startTimeout(callback, WARNING_TIME);
    events.map(event => window.addEventListener(event, abort));
    return () => {
      // cleanup timeouts/listeners
      logoutTimeout.current = clearTimeout(logoutTimeout.current);
      events.map(event => window.removeEventListener(event, abort));
    };
  }, [WARNING_TIME, abort, events, callback]);

  return abort;
}
