import axios from 'axios';
import { getLfsSoftQuoteProxyBaseUrl} from '@/utils/getUrlFromEnvUtil';
import {REQUEST_CHANNEL} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';

const GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL = getLfsSoftQuoteProxyBaseUrl();

export function postWrapper(url, headers, data) {
  return axios.post(url, data, {
    headers: {
      ...headers
    }
  });
}

export function quoteSubmit(data, requestChannel = REQUEST_CHANNEL.LFComAu) {
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/quote`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, data);
}

export function quoteResponse(id, requestChannel = REQUEST_CHANNEL.LFComAu) {
  const data = {
    quoteId: id
  };
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/quote/by-id`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, data);
}

export function validateBroker(validateBrokerCheckData, requestChannel = REQUEST_CHANNEL.LFComAu) {
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/validate-broker`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, validateBrokerCheckData);
}

export function checkForDuplicateQuote(
  duplicateCheckData,
  requestChannel = REQUEST_CHANNEL.LFComAu
) {
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/check-quote`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, duplicateCheckData);
}

export function retrieveQuote(
  data,
  requestChannel = REQUEST_CHANNEL.LFComAu
) {
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/validate-customer`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, data);
}

export function validateOtp(data, requestChannel = REQUEST_CHANNEL.LFComAu) {
  const url = `${GATSBY_LFS_SOFT_QUOTE_PROXY_BASE_URL}/quote-otp`;
  const headers = defaultHeaders(requestChannel);
  return postWrapper(url, headers, data);
}

const defaultHeaders = requestChannel => ({
  'Content-Type': 'application/json',
  'LFS-Request-Channel': requestChannel
});
