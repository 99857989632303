import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import Button from '@latitude/button/Button';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import {useSessionTimeout} from '@/components/PersonalLoanSoftQuote/SessionTimeout';

import * as modalStyles from './modal.module.scss';

const TimeoutContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .h4 {
    font-size: 32px;
    line-height: 36px;
    color: black;
  }
  .subtitle02 {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .countdownRed {
    color: #b3000c;
  }
`;
const TwoButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  button {
    height: 48px;
    flex: 1;
    padding: 0;
  }
  button:first-of-type {
    margin-right: 20px;
  }
`;
const SingleButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;

  button {
    height: 48px;
    flex: 1;
    padding: 0;
    margin: 0 auto;
    min-width: 100px;
    max-width: 30%;
  }

  @media (max-width: ${BREAKPOINT.SM}) {
    flex-direction: column;

    button {
      flex: none;
    }
  }
`;

export const TimeoutModalHandler = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const abort = useSessionTimeout(setModalOpen, props.broker);
  return (
    <>
      {modalOpen && <TimeoutModal abort={abort} setModalOpen={setModalOpen} broker={props.broker} isGem={props.isGem} />}
    </>
  );
};

export const TimeoutModal = ({ abort, setModalOpen, broker, isGem }) => {
  const MODAL_DURATION = 300;// shows the modal for 5 minutes
  const COUNTER_DURATION = 60;// shows the counter after 4 minutes (counter duration is 1 minute)
  const [seconds, setSeconds] = useState(MODAL_DURATION);
  const [sessionActive, setSessionActive] = useState(true);
  const [showTimer, setShowTimer] = useState(false);

  const sessionExpired = useCallback(() => {
    setSessionActive(false);
  }, [setSessionActive]);

  const stayLoggedIn = () => {
    setModalOpen(false);
    abort();
  };

  const closeModal = useCallback(() => {
    setModalOpen(false);
    let plHomeURL = '';
    if (broker) {
      plHomeURL = getUrlFromEnv('funnel-home-personal-loan-broker');
    } else if (isGem) {
      plHomeURL = getUrlFromEnv('funnel-home-personal-loan-gem');
    } else {
      plHomeURL = getUrlFromEnv('funnel-home-personal-loan');
    }
    window.location.href = plHomeURL;
  }, [setModalOpen]);

  useEffect(() => {
    const timeout = setInterval(() => {
      setSeconds(sec => {
        if (sec === 0) {
          sessionExpired();
          return sec;
        } else if (sec <= COUNTER_DURATION) {
          setShowTimer(true);
        }
        return sec - 1;
      });
    }, 1000);
    return () => clearInterval(timeout);
  }, [sessionExpired, setSeconds]);

  return (
    <Modal
      isOpen
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      onRequestClose={() => setModalOpen(false)}
      className={`${modalStyles.modal} ${modalStyles.timeoutModal}`}
      overlayClassName={modalStyles.overlay}
    >
      {sessionActive ?
      <TimeoutContentWrapper>
        <h1 className="h4">Your session is about to expire</h1>
        <div className="subtitle02">
          { showTimer ? 
         <>Your session will expire in {' '}
         <strong className={seconds <= 10 ? 'countdownRed' : ''}>
           {seconds}
         </strong>
         {' '} seconds due to inactivity. </> :<>Your session will expire due to inactivity. </>
        } 
          <>Do you want to continue your session?</>
        </div>
      </TimeoutContentWrapper>
      : 
      <TimeoutContentWrapper>
        <h1 className="h4">Session expired</h1>
        <div className="subtitle02">
          Your session has expired due to inactivity. You will be redirected to our { broker ? ' Brokers': ' Personal Loans'} page.
        </div>
      </TimeoutContentWrapper>
      }
      {sessionActive ? 
       <AnalyticsLocationProvider location="Your session is about to expire">
        <TwoButtonsWrapper>
          <Button variant="tertiary" type="button" onClick={() => closeModal()}>
            No, exit
          </Button>
          <Button type="button" onClick={() => stayLoggedIn()}>
            Yes, continue
          </Button>
        </TwoButtonsWrapper>
        </AnalyticsLocationProvider>
      : 
      <AnalyticsLocationProvider location="Session expired">
      <SingleButtonWrapper>
        <Button type="button" onClick={() => closeModal()}>
          Ok
        </Button>
      </SingleButtonWrapper>
      </AnalyticsLocationProvider>
      }
    </Modal>
  );
};