/* eslint-disable no-console */
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Grid, Text, ShadowContainer, Container, Flex, Stack } from 'basis';
import styled from '@emotion/styled';
import SvgInline from '@latitude/svg-inline';
import { Button } from '@latitude/button';
import { Link as ButtonLink } from '@latitude/link';
import Link from '@latitude/link/Link';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { BREAKPOINT, BUTTON_STYLE } from '@latitude/core/utils/constants';
import NumberFormat from 'react-number-format';
import { AnalyticsLocationProvider } from 'latitude-analytics/lib/analytics';
import {
  getRepaymentPeriodObject,
  purposeNzOptions,
  purposeOptions
} from '@/components/EstimateRateWidget/MainRateWidget';
import PLData from '@/data/pages/personal-loan-data.json';
import { getEnvForDebug, getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import ConfettiText from '@/components/ConfettiText/ConfettiText';
import Modal from '../Modal/Modal';
import { COLOR, LOAN_PAY_FREQUENCY } from '../../utils/constants';
import SoftQuoteRepayments from './SoftQuoteRepayments';

const DEBUG = getEnvForDebug();
const ApprovedMessage = props => {
  const broker = props.broker;
  const isBetterStart = props.isBetterStart;
  const [showSecuredModal, setShowSecuredModal] = useState(false);
  const [showBetterStartModal, setShowBetterStartModal] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);

  const PL_APPLY_URL = `${getUrlFromEnv('pl-apply-url-au')}${
    isBetterStart ? '-better-start' : ''
  }`;
  const PL_GEM_APPLY_URL = getUrlFromEnv('pl-apply-url-gem');
  const [applyUrl, setApplyUrl] = useState(PL_APPLY_URL);

  const analyticsLocation =
    (props.userData ? 'retrieve' : 'quote') + '-success';

  const loanPurpose =
    (typeof window !== 'undefined' && sessionStorage.getItem('purpose')) ??
    'purpose';

  const purposeOpts = props.isGem ? purposeNzOptions : purposeOptions;
  const loanText = purposeOpts.filter(
    purposeOptions => purposeOptions.value === loanPurpose
  );

  // Initialise the Repayment frequency with Monthly default
  if (typeof window !== 'undefined') {
    sessionStorage.setItem('frequency', LOAN_PAY_FREQUENCY.MONTHLY);
  }
  if (DEBUG) console.log('In ApprovedData, props=', props);
  useEffect(() => {
    if (props.broker) {
      let salesforceUrl = getUrlFromEnv('funnel-pl-start-application-button');
      // Note here we are not URL encoding the quote id (qId). The encrypted quote id passed in as a prop comes from the state.formValues.encryptedQuoteID,
      // which is set from the Sales Force API Response directly: values.encryptedQuoteID = response.data.encryptedQuoteID. The API response is not
      // subject to URL encoiding for transmission from server to client - However, the Sales Force API Server has already encoded the response.
      // The sales force API response payload looks like: `"encryptedQuoteID": "viy4BO580dBbEnu7J%2BTW0WsHRui%2F%2BuuMIwaWacE%2BqEPA%2FfNmx0yGRcpzZiZvFSQh"`
      // and this 73 character string:
      //   viy4BO580dBbEnu7J%2BTW0WsHRui%2F%2BuuMIwaWacE%2BqEPA%2FfNmx0yGRcpzZiZvFSQh
      // is a URL Encoded version of this 64 character string, which is the real
      //   viy4BO580dBbEnu7J+TW0WsHRui/+uuMIwaWacE+qEPA/fNmx0yGRcpzZiZvFSQh
      // so when we pass the quote id in as a URL parameter, we don't want to double-URL encode it, which would make it look like this 83 character string:
      //   viy4BO580dBbEnu7J%252BTW0WsHRui%252F%252BuuMIwaWacE%252BqEPA%252FfNmx0yGRcpzZiZvFSQ
      setApplyUrl(
        `${salesforceUrl}${isBetterStart ? '?subType=betterStart&' : '?'}${
          broker ? 'form=broker&' : ''
        }qId=${props.encryptedQuoteID}`
      );
    } else if (props.isGem) {
      setApplyUrl(
        `${PL_GEM_APPLY_URL}?qId=${encodeURIComponent(props.encryptedQuoteID)}`
      );
    } else {
      if (
        typeof window !== 'undefined' &&
        sessionStorage.getItem('partnername')
      ) {
        setApplyUrl(
          `${PL_APPLY_URL}${
            isBetterStart ? '?subType=betterStart&' : '?'
          }partnername=${sessionStorage.getItem(
            'partnername'
          )}&qId=${encodeURIComponent(props.encryptedQuoteID)}`
        );
      } else {
        setApplyUrl(
          `${PL_APPLY_URL}${
            isBetterStart ? '?subType=betterStart&' : '?'
          }qId=${encodeURIComponent(props.encryptedQuoteID)}`
        );
      }
    }
  }, []);

  /**
   * September 2021 - better start implementation - the session is used to store the loan details for the main rate widget.
   * When ApprovedMessage is rendered from RetrieveQuote, we pass in the loanAmount as a prop. This prop value comes directly from the API response.
   * We leave the session access here for when this component is rendered by other parent components.
   */
  let loanAmount;
  if (props.loanAmount) {
    loanAmount = props.loanAmount;
  } else {
    loanAmount =
      (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
      (broker ? '4000' : '3000');
  }

  const repaymentPeriod =
    (typeof window !== 'undefined' &&
      sessionStorage.getItem('repaymentPeriod')) ??
    '5';
  const repaymentPeriodOption = getRepaymentPeriodObject({
    isBetterStart,
    isGem: props.isGem,
    value: repaymentPeriod
  });
  const loanTermParam = repaymentPeriodOption
    ? repaymentPeriodOption.text
    : `${repaymentPeriod} year(s)`;

  const pageTitle = props.broker
    ? `Here's ${_.capitalize(props.name)}'s rate`
    : `Here's your rate, ${_.capitalize(props.name)}`;

  const approvalCriteriaText = props.isGem ? (
    <>
      All applications are subject to lending and approval criteria. Fees apply
      including a $240 establishment fee. Interest rates are fixed for the life
      of the loan.
    </>
  ) : (
    <>
      All applications are subject to lending and approval criteria, which may
      impact the amount {broker ? 'your client' : 'you'} can borrow.
      {broker && (
        <>
          The approved loan amount will be confirmed once the application is
          submitted and assessed.
        </>
      )}
    </>
  );

  const contactNumber = props.isGem
    ? 'tel:0800422898'
    : broker
    ? 'tel:1800035902'
    : 'tel:1300973422';

  const buttonContactNumber = props.isGem
    ? 'Call 0800 422 898'
    : broker
    ? 'Call 1800 035 902'
    : 'Call 1300 973 422';

  const securedLoanInfo1 = broker ? (
    <p>
      When applying for a personal loan, one decision your client will need to
      make is whether they want a secured or unsecured loan.
    </p>
  ) : (
    <p>
      When applying for a personal loan, one decision you&apos;ll need to make
      is whether you want a secured or unsecured loan.
    </p>
  );

  const securedLoanInfo2 = broker ? (
    <p>
      Taking out a <b>secured</b> loan means they&apos;re able to provide an
      asset like their car for the lender to secure their loan against. During
      the application process they can provide details of their personal asset
      including the asset type, value and ownership status. If your
      client&apos;s asset ownership is verified, their loan will be secured and
      they may be eligible for a <b>lower interest rate</b>.
    </p>
  ) : (
    <p>
      Taking out a <b>secured</b> loan means you’re able to provide an asset
      like your car, for the lender to secure your loan against. During the
      application process you can provide details of your personal asset
      including the asset type, value and ownership status. If your asset
      ownership is verified, your loan will be secured and you may be eligible
      for a <b>lower interest rate</b>.
    </p>
  );
  const securedLoanInfo3 = broker ? (
    <p>
      If they don&apos;t have a suitable asset to secure their loan against,
      they may decide to apply for an <b> unsecured </b> loan.
    </p>
  ) : (
    <p>
      If you don&apos;t have a suitable asset to secure your loan against, you
      may decide to apply for an
      <b> unsecured </b> loan.
    </p>
  );
  const interestRateModalHeader = broker
    ? "How is my client's interest rate determined?"
    : 'How is my interest rate determined?';

  const phoneNumber = props.isGem
    ? '0800 422 898'
    : broker
    ? '1800 035 902'
    : PLData.quotePhoneNumber;
  const disableDefaultFunnelTracking = broker ? true : false;
  const applyBtnProps = props.queryString?.includes('email-update')
    ? {
        href: 'javascript:;',
        onClick: () => {
          window.scrollTo(0, 0);
          props.dispatch.setUserData({
            ...props.userData,
            userStatus: 'retrieve'
          });
        }
      }
    : { href: applyUrl, target: '_self' };

  return (
    <Container margin="11 0 0" margin-lg="4 0 0">
      <AnalyticsLocationProvider location={analyticsLocation}>
        <StickyContainer>
          <StickyCtaContainer>
            <StickyCta>
              <div className="sticky-cta__inner">
                <Link
                  noStyle
                  className="sticky-cta__phone text-white"
                  trackId="sticky-cta-phone"
                  href={`tel:${phoneNumber}`}
                  trackEventData={{
                    label: phoneNumber
                  }}
                >
                  <SvgInline
                    strokeWhite
                    fillWhite
                    className="sticky-cta__phone-icon text-white"
                    name="phone"
                  />
                  <span className="sticky-cta__phone-number text-white">
                    {phoneNumber}
                  </span>
                </Link>

                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  trackId="sticky-cta--apply-now"
                  className="sticky-cta__button"
                  trackEventData={{
                    category: 'cta',
                    action: 'discovery-link'
                  }}
                  {...applyBtnProps}
                  disableDefaultFunnelTracking={disableDefaultFunnelTracking}
                >
                  Apply now
                </Link>
              </div>
            </StickyCta>
          </StickyCtaContainer>
          <StickyNavContainer>
            <StickyNavigation
              items={[
                {
                  anchor: 'rate',
                  label: broker ? "Your client's rate" : 'Your rate'
                },
                {
                  anchor: 'calculate',
                  label: 'Calculate repayments'
                }
              ]}
              phoneNumber={phoneNumber}
              ctaHref={applyUrl}
              ctaText="Apply now"
              ctaProps={{
                target: '_self',
                disableDefaultFunnelTracking
              }}
              disableDefaultFunnelTracking={disableDefaultFunnelTracking}
            />
          </StickyNavContainer>
        </StickyContainer>

        <Grid preset="page" rowsGap={7}>
          <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="3-8">
            <ConfettiText header text={pageTitle} />
          </Grid.Item>

          <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="3-8">
            <SubHeaderText broker={broker} />
          </Grid.Item>

          <Grid.Item colSpan="all">
            <QuoteReference>
              Quote reference: {props.quoteNumber}
            </QuoteReference>
          </Grid.Item>

          <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="3-8">
            <Anchor name="rate" id="rate" css="top:-100px;" />
            <ShadowContainer
              shadowSize="small"
              shadowColor="purple"
              margin="0 10 7 0"
              margin-lg="0 0 7 0"
              padding="5"
            >
              <Container>
                <Stack align="center" gap="4">
                  <TextRegular align="center">
                    Your {broker && `client's `}personalised rate for an
                    unsecured <strong>{loanText[0]?.text}</strong> loan:
                  </TextRegular>
                  <Stack align="center" gap="2">
                    <RateContainer>
                      <TextRegular align="center">Fixed Rate</TextRegular>
                      <Flex placeItems="center">
                        <Text textStyle="heading3">{props.interestRate}%</Text>
                        <Text textStyle="heading5" margin="1 0 0 1">
                          p.a.
                        </Text>
                      </Flex>
                    </RateContainer>
                  </Stack>
                  <LoanInfoContainer>
                    <RateContainer>
                      <TextRegular align="center">Loan Amount</TextRegular>
                      <Flex placeItems="center">
                        <Text textStyle="heading3" align="center">
                          <NumberFormat
                            value={loanAmount}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                          />
                        </Text>
                      </Flex>
                    </RateContainer>
                    <RateContainer>
                      <TextRegular align="center">Loan Term</TextRegular>
                      <Flex placeItems="center">
                        <Text textStyle="heading3" align="center">
                          {loanTermParam}
                        </Text>
                      </Flex>
                    </RateContainer>
                  </LoanInfoContainer>

                  {props.securedInterestRate && (
                    <RateInfoContainer>
                      <Text textStyle="body2">
                        The above Fixed Rate is for an unsecured loan. You could
                        get a rate of{' '}
                        <strong>{props.securedInterestRate}% p.a.</strong> if
                        you secure your loan against an asset during the
                        application process.{' '}
                        <Link
                          href="#"
                          onClick={e => {
                            setShowSecuredModal(true);
                            e.preventDefault();
                          }}
                          newTab={false}
                        >
                          Learn more
                        </Link>
                      </Text>
                    </RateInfoContainer>
                  )}
                  <RateInfoContainer>
                    <Text textStyle="body2">{approvalCriteriaText}</Text>
                  </RateInfoContainer>
                </Stack>
              </Container>
            </ShadowContainer>
          </Grid.Item>
          <Grid.Item colSpan="all" colSpan-md="2-5" colSpan-lg="3-8">
            {isBetterStart && (
              <Stack direction="horizontal" align="center">
                <ModalIcon />
                <Text textStyle="body2" margin="0 0 2 0">
                  <Link
                    href="#"
                    newTab={false}
                    onClick={e => {
                      setShowBetterStartModal(true);
                      e.preventDefault();
                    }}
                    trackEventData={{
                      label: 'Why is my loan term extended by 3 months?'
                    }}
                  >
                    Why is my {broker && `client's `}loan term extended by 3
                    months?
                  </Link>
                </Text>
              </Stack>
            )}
            <Stack direction="horizontal" align="center">
              <ModalIcon />
              <Text textStyle="body2" margin="0 0 2 0">
                <Link
                  href="#"
                  newTab={false}
                  onClick={e => {
                    setShowRateModal(true);
                    e.preventDefault();
                  }}
                  trackEventData={{
                    label: 'How is my interest rate determined?'
                  }}
                >
                  How is my {broker && `client's `} interest rate determined?
                </Link>
              </Text>
            </Stack>
          </Grid.Item>

          <Anchor name="calculate" id="calculate" css="top:-50px;" />
          <Grid.Item colSpan="all">
            <Container>
              <Text as="h4" textStyle="heading4" align="center">
                {broker ? 'Calculate repayments' : 'Calculate your repayments'}
              </Text>
              <SoftQuoteRepayments
                interestRate={props.interestRate}
                broker={props.broker}
                brokerFee={props.brokerFee}
                isGem={props.isGem}
                isBetterStart={isBetterStart}
              />
            </Container>
          </Grid.Item>

          <Grid.Item colSpan="all" colSpan-md="1-6" colSpan-lg="3-8">
            <Text as="h4" textStyle="heading4" align="center" margin="0 0 4 0">
              Apply now to lock in your {broker && `client's `} rate
            </Text>

            <ClientInfoContainer>
              <Text color="grey.t75">
                To ensure {broker ? 'they' : 'you'} receive this rate offer,
                please apply now. However, if any of your{' '}
                {broker && `client's `}
                personal information for the quote differs in the application
                form or if {broker ? 'their' : 'your'} credit file information
                changes in the meantime, we may not be able to offer this
                interest rate quoted.
              </Text>
            </ClientInfoContainer>

            <ButtonContainer>
              <CtaButton button="tertiary" href={contactNumber}>
                {buttonContactNumber}
              </CtaButton>
              <CtaButton
                button="primary"
                trackEventData={{
                  category: 'cta',
                  action: broker ? 'application-link' : 'discovery-link'
                }}
                {...applyBtnProps}
                disableDefaultFunnelTracking={disableDefaultFunnelTracking}
              >
                Apply now
              </CtaButton>
            </ButtonContainer>

            {props.isGem ? (
              <Text align="center" color="grey.t75" margin="4 0 0 0">
                Call us Mon to Fri: 8.30am - 6.30pm (NZST) <br /> Weekends:
                Closed
              </Text>
            ) : (
              <Text align="center" color="grey.t75" margin="4 0 0 0">
                Call us Mon to Fri: 8am - {broker ? 6 : 7}pm (AEST) <br />{' '}
                Weekends: Closed
              </Text>
            )}
          </Grid.Item>
        </Grid>

        <Modal
          className="w-40"
          isOpen={showSecuredModal}
          title="What is a secured loan?"
          onRequestClose={() => {
            setShowSecuredModal(false);
          }}
          content={
            <>
              {securedLoanInfo1}
              {securedLoanInfo2}
              {securedLoanInfo3}
            </>
          }
        />
        <Modal
          className="w-40"
          isOpen={showBetterStartModal}
          title={`Why is my ${
            broker ? "client's " : ''
          }loan term extended by 3 months?`}
          onRequestClose={() => {
            setShowBetterStartModal(false);
          }}
          content="A Better Start Personal Loan term is longer than a standard loan term and is extended by an extra 3 months due to the 3 month no repayment and no interest period."
        />
        <Modal
          className="w-40"
          isOpen={showRateModal}
          title={interestRateModalHeader}
          onRequestClose={() => {
            setShowRateModal(false);
          }}
          content={
            <>
              The interest rate {broker ? 'your client' : 'you'} gets depends on
              {broker ? ' their' : ' your'} personal circumstances and
              {broker ? ' their' : ' your'} current &apos;credit score&apos;.
              <br />
              <br />
              <strong>
                {broker ? 'Their' : 'Your'} personal circumstances
              </strong>
              We look at {broker ? ' their' : ' your'} financial situation
              including how long {broker ? ' they' : ' you'}&apos;ve been in
              stable employment, if {broker ? ' they' : ' you'}&apos;re a
              homeowner or have a mortgage, and if {broker ? ' they' : ' you'}{' '}
              have a stable residential status.
              <br />
              <br />
              <strong>{broker ? 'Their' : 'Your'} credit score</strong>
              {broker ? 'They' : 'You'} are given a credit score (sometimes
              called a credit &apos;rating&apos;) based on{' '}
              {broker ? ' their' : ' your'} financial history.
              <br />
              <br />
              {!props.isGem && (
                <Button
                  variant="tertiary"
                  onClick={() =>
                    window.open(
                      'https://www.latitudefinancial.com.au/how-interest-rates-are-determined/'
                    )
                  }
                >
                  <SvgInline name="external-link" css="margin-right:8px;" />
                  Find out more
                </Button>
              )}
            </>
          }
        />
      </AnalyticsLocationProvider>
    </Container>
  );
};

const Anchor = styled.div`
  pointer-events: none;
  position: relative;
`;

const StickyContainer = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  z-index: 1000;
`;

const StickyCtaContainer = styled.div`
  @media (min-width: ${BREAKPOINT.LG}) {
    display: none;
  }
`;

const StickyNavContainer = styled.div`
  display: none;
  border-top: 1px solid #dcdcdc;

  .sticky-navigation__cta-group {
    opacity: 1;
    pointer-events: unset;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    display: block;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    .sticky-navigation__inner {
      max-width: 1140px;
    }
  }
`;

const SubHeaderText = props => {
  return props.broker ? (
    <Text align="left" color="grey.t75">
      Based on the details you’ve submitted, your client&apos;s personalised
      interest rate and repayment amount (
      <strong>including fees and charges</strong>) can be found below. Any
      previous history with Latitude has been taken into account.
    </Text>
  ) : (
    <Text align="left" color="grey.t75">
      Based on the details you’ve submitted, your personalised interest rate and
      repayment amount (<strong>including fees and charges</strong>) can be
      found below. Your quote has been <strong>emailed</strong> to you and is
      valid for <strong>14 days</strong>.
    </Text>
  );
};

const QuoteReference = props => {
  const Outer = styled.div`
    text-align: center;
    p {
      display: inline-block;
      background: ${COLOR.PINK_BABY};
      padding: 6px 18px;
    }
  `;
  return (
    <Outer>
      <Text textStyle="heading6">{props.children}</Text>
    </Outer>
  );
};

const TextRegular = styled.p`
  margin: 0;
  text-align: center;
  font-weight: 400;
`;

const RateContainer = styled.div`
  p {
    color: #000;
  }
`;

const RateInfoContainer = styled.div`
  display: flex;
  margin: auto;
  width: 100%;
  max-width: 350px;
`;

const LoanInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  width: 350px;
  max-width: 350px;
  & > div {
    margin: 0 16px;
  }
`;

const ClientInfoContainer = styled.div`
  @media (max-width: ${BREAKPOINT.SM}) {
    margin: 0 0px 16px 0px;
  }
  margin-bottom: 16px;
`;

const ModalIcon = props => {
  const marginLeft = props.marginLeft ?? '0';
  const marginBottom = props.marginBottom ?? '0';
  const Icon = styled(SvgInline)`
    height: 18px;
    width: 18px;
    min-width: 18px;
    margin-right: 8px;
    margin-left: ${marginLeft};
    margin-bottom: ${marginBottom};
  `;
  return <Icon name="info-block-outline" />;
};
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CtaButton = styled(ButtonLink)`
  width: calc(50% - 8px);
  font-size: 18px;
`;

export default ApprovedMessage;
