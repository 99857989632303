const unitAddressRegex = RegExp('^[0-9]+[a-zA-Z]*$');
export const streetNumberAddressRegex = RegExp('^[A-Za-z0-9/-]*$');
const streetNameAddressRegex = RegExp('^[A-Za-z -]*$');
const suburbAddressRegex = RegExp("^[A-Za-z '-]*$");
const propertyRegex = RegExp("^[A-Za-z0-9 '-]*$");
const postcodeAddressRegex = RegExp('^[0-9]{4}$');

function validate(minLength, maxLength, regex, invalidMessage) {
  return value => {
    const { length } = value.trim();

    if (length < minLength) {
      return invalidMessage;
    }

    if (length > maxLength) {
      return invalidMessage;
    }

    if (!regex.test(value)) {
      return invalidMessage;
    }

    return null;
  };
}

export function validateWithLength({ minLength, maxLength, fieldName, regex, invalidMessage }) {
  return value => {
    const fieldLength = value.trim().length;
    const errors = [];

    if ((minLength && fieldLength < minLength) || (maxLength && fieldLength > maxLength)) {
      errors.push(`Enter a ${fieldName} between ${minLength}-${maxLength} characters`);
    }

    if (!regex.test(value)) {
      errors.push(invalidMessage);
    }
    return errors.length > 0 ? errors :  null;
  };
}

function validatePostcode(length, regex, invalidMessage) {
  return value => {
    const stringLength = value.trim().length;

    if (stringLength !== length) {
      return invalidMessage;
    }

    if (!regex.test(value)) {
      return invalidMessage;
    }

    return null;
  };
}

export function validateSelect(invalidMessage) {
  return value => {
    const stringLength = value.trim().length;

    if (stringLength === 0) {
      return invalidMessage;
    }

    return null;
  };
}

export const unitAddressValidation = validate(
  1,
  4,
  unitAddressRegex,
  'Enter a unit number between 1-4 characters',
  true,
);
export const streetNumberAddressValidation = validateWithLength({
  minLength: 1,
  maxLength: 9,
  fieldName: 'street number',
  regex: streetNumberAddressRegex,
  invalidMessage: 'Enter a valid street number'
});
export const streetNameAddressValidation = validateWithLength({
  minLength: 1,
  maxLength: 14,
  fieldName: 'street name',
  regex: streetNameAddressRegex,
  invalidMessage: 'Enter a valid street name'
});

export const suburbAddressValidation = validateWithLength({
  minLength: 1,
  maxLength: 22,
  fieldName: 'suburb',
  regex: suburbAddressRegex,
  invalidMessage: 'Enter a valid suburb'
});

export const postcodeAddressValidation = validatePostcode(
  4,
  postcodeAddressRegex,
  'Enter a 4 digit postcode',
);

export const streetTypeValidation = validateSelect(`Select a street type`);

export const auStateValidation = validateSelect(`Select a state`);

export const propertyNameValidation = validateWithLength({
  minLength: 1,
  maxLength: 20,
  fieldName: 'property name',
  regex: propertyRegex,
  invalidMessage: 'Please use letters, numbers, hyphens and apostrophes only'
});

export const residentialAddressErrorMessage = 'Enter a residential address';
export const residentialAddressServerErrorMessage = 'Search for an address again or enter it manually';
