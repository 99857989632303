/* eslint-disable no-console */

import React, { useState, useEffect, useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import { Button, Container, Form, Grid, Input, Message, Text } from 'basis';
import styled from 'styled-components';
import { PL_GEM_TEL, PL_TEL } from '@/utils/constants';
import {
  generateOtp,
  Title,
  WideButtonContainer
} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuoteSection';
import NeedHelpSection from '@/components/PersonalLoanSoftQuote/partials/NeedHelpSection';
import { getEnvForDebug } from '@/utils/getUrlFromEnvUtil';
import {
  REQUEST_CHANNEL,
  RETRIEVE_USER_STATUS,
  SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {validateOtp} from '@/components/PersonalLoanSoftQuote/library/softQuoteProxyApi';
import tealiumConfig from '../../../../tealium';

const DEBUG = getEnvForDebug();
export const VerifySmsSection = props => {
  const [analytics] = useContext(AnalyticsContext);

  const [forceRerender, setForceRerender] = useState(); // used to clear input after generating a new otp

  const isGem = props.isGem;
  const phoneNumber = isGem ? PL_GEM_TEL : PL_TEL;
  const requestChannel = isGem ? REQUEST_CHANNEL.GFCoNz : REQUEST_CHANNEL.LFComAu;

  function verifySmsSubmit(errors, values) {
    if (!Object.keys(errors).length > 0) {
      trackEvent(analytics, {
        category: 'quote',
        action: 'quote-retrieval-verify',
        location: 'Quote-Retrieval-Verify',
        label: 'Retrieve your details'
      });
      props.dispatch.setLoadingOverlayIsActive(true);

      const postData = {
        transactionId: props.userData.transactionId,
        quoteId: props.userData.quoteId,
        otp: values.code
      };

      verifyOtp(postData, props.userData, props.dispatch, requestChannel);
    }
  }

  function handleResend() {
    const postData = {
      dateOfBirth: props.userData.dateOfBirth,
      emailID: props.userData.emailId
    };

    props.dispatch.setLoadingOverlayIsActive(true);

    generateOtp(postData, props.userData, props.dispatch, () => {
// force remount of parent to clear input
      setForceRerender(Math.random());
    }, isGem, analytics);
  }
  // analytics - if this is a gem page, change the `siteCountry` to `nz`
  useEffect(() => {
    if (isGem) tealiumConfig.siteData.page.siteCountry = 'nz';
  }, []);

  return (
    <>
      <Title>Enter your code</Title>
      <Text align="center">
        We&apos;ve sent a SMS with a verification code to{' '}
        {props.userData.phoneNumber}
      </Text>

      {props.userData.newCodeRemaining >= '0' &&
      props.userData.codeMatchAttemptsRemaining > '0' &&
      props.userData.codeMatchAttemptsRemaining < '3' &&
      !props.userData.expiredOtp && (
        <Message severity="warning-or-significant" bg="secondary.pink.t30">
          The SMS code you&apos;ve entered is incorrect. Please try again.
        </Message>
      )}

      {props.userData.newCodeRemaining > '0' &&
      props.userData.codeMatchAttemptsRemaining == '0' && (
        <Message severity="blocking" bg="secondary.pink.t30">
          You&apos;ve reached the maximum number of attempts to enter your SMS
          code. Please{' '}
          <a onClick={() => {
            handleResend();
          }}
         onKeyDown={() => {
           handleResend();
         }}
         role="link"
         tabIndex={0}
          >resend</a> a new code.
        </Message>
      )}

      {props.userData.newCodeRemaining == '0' &&
        props.userData.codeMatchAttemptsRemaining == '0' && (
          <Message severity="warning-or-significant" bg="secondary.pink.t30">
            You&apos;ve reached the maximum number of attempts to enter your SMS
            code. Please call us on{' '}
            <a
              href={`tel:${phoneNumber}`}
              onClick={() => {
                trackEvent(analytics, {
                  category: 'quote',
                  action: 'phone-link',
                  label: phoneNumber,
                  location: 'Quote-Retrieval-Verify-max-attempts',
                });
              }}
            >
              {phoneNumber}
            </a>{' '}
            to retrieve your rate.
          </Message>
        )}

      {props.userData.newCodeRemaining == '0' &&
      props.userData.expiredOtp && (
        <Message severity="warning-or-significant" bg="secondary.pink.t30">
          You&apos;ve reached the maximum number of attempts to enter your SMS
          code. Please call us on{' '}
          <a href={`tel:${phoneNumber}`}>{phoneNumber}</a> to retrieve your
          rate.
        </Message>
      )}

      {
      props.userData.otpSent &&
      !props.userData.expiredOtp && (
        <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
          We have sent a new SMS verification code
        </Message>
      )}

      {props.userData.expiredOtp &&
      props.userData.newCodeRemaining > '0' && (
        <Message severity="warning-or-significant" bg="secondary.pink.t30">
          The SMS code you&apos;ve entered is expired. Please{' '}
          <a onClick={() => {
            handleResend();
          }}
         onKeyDown={() => {
           handleResend();
         }}
         role="link"
         tabIndex={0}
          >resend</a> a new code.
        </Message>
      )}

      <Container bg="grey.t07" margin="0 4" margin-md="0" padding="4">
        <Form
          initialValues={{
            code: ''
          }}
          onSubmit={({ errors, values }) => {
            verifySmsSubmit(errors, values);
          }}
          key={forceRerender}
        >
          <Grid rowsGap={4}>
            <CodeInputContainer>
              <Input
                name="code"
                label="Enter your 6 digit code"
                variant="numeric"
                key={name}
                validate={(value, args, length = 6) => {
                  if (value.length != length) {
                    return `Please enter a code containing ${length} digits`;
                  }
                  if (!/^\d+$/.test(value)) {
                    return `Only 0-9 are allowed`;
                  }
                  return null;
                }}
              />
            </CodeInputContainer>

            <WideButtonContainer
              buttonIsDisabled={
                props.userData.codeMatchAttemptsRemaining <= '0'
              }
            >
              <Button
                type="submit"
                color="green"
                width="100%"
              >
                Retrieve your details
              </Button>
            </WideButtonContainer>

            <Text align="center">
              {props.userData.newCodeRemaining > '0' && (
                <>
                  Didn&apos;t get your code?{' '}
                  <span
                    css="text-decoration:underline; cursor:pointer; outline: none;"
                    role="button"
                    onKeyPress={() => {}}
                    tabIndex={0}
                    onClick={() => {
                      trackEvent(analytics, {
                        category: 'quote',
                        action: 'send-code',
                        label: 'Resend',
                        location: 'Quote-Retrieval-Verify',
                      });
                      handleResend();
                    }}
                  >
                    Resend
                  </span>
                </>
              )}
            </Text>
          </Grid>
        </Form>
      </Container>

      <NeedHelpSection isGem={isGem} />
    </>
  );
};

/* verifyOtp
 * Verify that user input matches genterted OTP
 * args:
 *    postData - object - data to post to salesforce api
 *    userData - object - state values from parent component
 *    dispatch - function - updates state in parent component
 */
function verifyOtp(postData, userData, dispatch, requestChannel) {
  validateOtp(postData, requestChannel)
    .then(function(response) {
      dispatch.setLoadingOverlayIsActive(false);
      if (DEBUG) console.log(response);

      if (
        response.data.status == SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.SUCCESS
      ) {
        // Success - OTP Generated Successfully.
        dispatch.setUserData({
          ...userData,
          interestRate: response.data.data.QUOTE_INTEREST_RATE,
          securedInterestRate: response.data.data.QUOTE_SECURED_INTEREST_RATE,
          quoteNumber: response.data.data.QUOTE_REFERENCE,
          encryptedQuoteID: response.data.data.ENCRYPTED_QUOTE_ID,
          quoteId: response.data.data.QUOTE_ID,
          name: response.data.data.QUOTE_FIRST_NAME,
          firstName: response.data.data.QUOTE_FIRST_NAME,
          loanAmount: parseInt(response.data.data.QUOTE_LOAN_AMOUNT),
          loanTerm: response.data.data.QUOTE_LOAN_TERM,
          loanPurpose: response.data.data.QUOTE_LOAN_PURPOSE,
          isBetterStart: (response.data.data.QUOTE_SUBTYPE === 'Better Start'),
          userStatus: RETRIEVE_USER_STATUS.proceed
        });
        if (DEBUG) console.log(response.data.data.SUCCESS_MSG);
      }

      if (
        response.data.status == SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.ERROR
      ) {
        // Error - OTP_EXPIRED - OTP has expired. This is different to quote expired
        // Will occur when the latest OTP generated is EXPIRED.
        // Previous OTP will be treated as OTP_INCORRECT.
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.OTP_EXPIRED in
          response.data.error
        ) {
          dispatch.setUserData({
            ...userData,
            expiredOtp: true,
            otpSent: false
          });
          if (DEBUG) console.log(response.data.error.OTP_EXPIRED);
        }

        // Error - QUOTE_ID_BLANK - Quote Id is mandatory and cannot be blank.
        // Failover status
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.QUOTE_ID_BLANK in
          response.data.error
        ) {
          if (DEBUG) console.log(response.data.error.QUOTE_ID_BLANK);

          dispatch.setLoadingOverlayIsActive(false);
          dispatch.setUserData({
            ...userData,
            userStatus: RETRIEVE_USER_STATUS.server_error
          });
        }

        // Error - TRANSACTION_ID_BLANK - Transaction Id is mandatory and cannot be blank.
        // Failover status
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.TRANSACTION_ID_BLANK in
          response.data.error
        ) {
          if (DEBUG) console.log(response.data.error.TRANSACTION_ID_BLANK);

          dispatch.setLoadingOverlayIsActive(false);
          dispatch.setUserData({
            ...userData,
            userStatus: RETRIEVE_USER_STATUS.server_error
          });
        }

        // Error - OTP_BLANK - OTP is mandatory and cannot be blank.
        // Failover status
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.OTP_BLANK in
          response.data.error
        ) {
          if (DEBUG) console.log(response.data.error.OTP_BLANK);

          dispatch.setLoadingOverlayIsActive(false);
          dispatch.setUserData({
            ...userData,
            userStatus: RETRIEVE_USER_STATUS.server_error
          });
        }

        // Error - INCORRECT_DETAILS.
        // Failover status
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.INCORRECT_DETAILS in
          response.data.error
        ) {
          if (DEBUG) console.log(response.data.error.INCORRECT_DETAILS);

          dispatch.setLoadingOverlayIsActive(false);
          dispatch.setUserData({
            ...userData,
            userStatus: RETRIEVE_USER_STATUS.server_error
          });
        }

        // Error - OTP_INCORRECT - Incorrect Details Provided for validation.
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.OTP_INCORRECT in
          response.data.error
        ) {
          dispatch.setUserData({
            ...userData,
            codeMatchAttemptsRemaining:
            response.data.data.VERIFICATION_ATTEMPTS_REMAINING,
            otpSent: false
          });
          if (DEBUG) console.log(response.data.error.OTP_INCORRECT);
        }

        // Error - OTP_ALREADY_VERIFIED - The OTP has already been verified.
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.OTP_ALREADY_VERIFIED in
          response.data.error
        ) {
          if (DEBUG) console.log(response.data.error.OTP_ALREADY_VERIFIED);
        }
        if (SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.ERROR_SENDING_SMS in response.data.error) {
          dispatch.setLoadingOverlayIsActive(false);
          dispatch.setUserData({
            ...userData,
            userStatus: RETRIEVE_USER_STATUS.server_error
          });
          if (DEBUG) console.log(response.data.error.ERROR_SENDING_SMS);
        }
        // Error - MAX_SMS_ATTEMPTS_REACHED - You have exhausted your maximum attempts to generate an OTP.
        // Happens when codeMatchAttemptsRemaining(VERIFICATION_ATTEMPTS_REMAINING) is 0.
        // Salesforce wont send OTP_INCORRECT with VERIFICATION_ATTEMPTS_REMAINING 0 but instead it will send this status.
        if (
          SALESFORCE_RETRIEVE_VALIDATE_OTP_STATUS.OTP_VERIFICATION_MAX_ATTEMPTS in
          response.data.error
        ) {
          dispatch.setUserData({
            ...userData,
            codeMatchAttemptsRemaining: 0,
            newCodeRemaining: 0,
            otpSent: false
          });
          if (DEBUG) console.log(response.data.error.MAX_SMS_ATTEMPTS_REACHED);
        }
      }
    })
    .catch(function(error) {
      dispatch.setLoadingOverlayIsActive(false);
      dispatch.setUserData({
        ...userData,
        userStatus: RETRIEVE_USER_STATUS.server_error
      });
      if (DEBUG) console.log(error);
    });
}

const CodeInputContainer = styled.div`
  & > div > div > input {
    text-align: center;
    letter-spacing: 1em;
  }
`;
