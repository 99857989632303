/* eslint-disable */

import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Flex } from '@latitude/box';
import EstimateRateWidget from '@/components/EstimateRateWidget/EstimateRateWidget';
import CalculatorTotals from '@/components/PersonalLoanCalculator/CalculatorTotals';
import {
  LOAN_PAY_FREQUENCY,
  PL_MONTHLY_FEE,
  BREAKPOINT,
  COLOR,
  PL_SP_FEE_AMOUNT,
  PL_SETTLEMENT_FEE_SP,
  PL_SETTLEMENT_FEE
} from '../../utils/constants';
import { getRepaymentFigures } from '../../utils/loanCalculatorUtil';
import {PL_SETTLEMENT_FEE_GEM} from '@/utils/constants';

const StyledWidgetContainer = styled('div')`
  margin-left: -16px;
  margin-right: -16px;
  margin-bottom: -32px;
  padding-top: 20px;

  @media (max-width: 374px) {
    width: 100%;
    margin: 0;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    padding-top: 0px;
    margin: 0px;
    width: 50%;
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    align-items: stretch;
    .mb-4 {
      padding-bottom: 8px;
    }
  }

  & > div > div {
    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: 504px;
      margin: auto;
    }
    @media (min-width: ${BREAKPOINT.MD}) {
      max-width: 684px;
      margin: auto;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      max-width: 770px;
      height: 100%;
      padding-top: 16px;
      & > p {
        margin-bottom: 40px;
      }
    }
  }
`;

const CalculatorTotalsContainer = styled('div')`
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: none;
    width: 50%;
  }

  & > div:before {
    border-top-color: ${COLOR.BLUE_BABY};
  }
  & > div {
    @media (min-width: ${BREAKPOINT.SM}) {
      max-width: 504px;
      margin-left: auto;
      margin-right: auto;
      overflow: hidden;
    }
    @media (min-width: ${BREAKPOINT.MD}) {
      max-width: 684px;
      margin-left: auto;
      margin-right: auto;
    }
    @media (min-width: ${BREAKPOINT.LG}) {
      margin-left: 0px;
      width: 100%;
    }
  }
`;

const StyledCalculatorFlex = styled(Flex)`
  flex-direction: column;
  @media (min-width: ${BREAKPOINT.LG}) {
    max-width: 930px;
    margin: auto;
    flex-direction: row;
    padding-top: 30px;
  }
`;

const SoftQuoteRepayments = props => {
  const [loanAmount, setLoanAmount] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
    props.broker ? '4000' : '3000'
  );
  const [frequency, setFrequency] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('frequency')) ??
      LOAN_PAY_FREQUENCY.MONTHLY
  );
  const [repaymentPeriod, setRepaymentPeriod] = useState(
    (typeof window !== 'undefined' &&
      sessionStorage.getItem('repaymentPeriod')) ??
      '5'
  );

  const [brokerFee, setBrokerFee] = useState(
    (typeof window !== 'undefined' && sessionStorage.getItem('brokerFee')) ??
      props.brokerFee ?? 0
  );

  const interestRate = props.interestRate;

  const [repaymentAmount, setRepaymentAmount] = useState(0);
  const [totalInterestPaid, setTotalInterestPaid] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);

  const [extraRepayments, setExtraRepayments] = useState(0);
  const [interestSaved, setInterestSaved] = useState(0);
  const [timeSavedYears, setTimeSavedYears] = useState(0);
  const [timeSavedMonths, setTimeSavedMonths] = useState(0);

  const [widgetValues, setWidgetValues] = useState({});

  const setTimeSavedValues = timeSaved => {
    // average amount of weeks in a month
    const weekToMonthModifier = 4.35;

    // calculate total time saved in months based on repayment frequency
    let timeSavedInMonths = timeSaved;
    if (frequency === LOAN_PAY_FREQUENCY.FORTNIGHTLY) {
      timeSavedInMonths = (timeSaved / weekToMonthModifier) * 2;
    } else if (frequency === LOAN_PAY_FREQUENCY.WEEKLY) {
      timeSavedInMonths = timeSaved / weekToMonthModifier;
    }

    // update time saved years and months to absolute rounded value
    timeSavedInMonths = Math.abs(Math.round(timeSavedInMonths));

    setTimeSavedYears(Math.floor(timeSavedInMonths / 12));
    setTimeSavedMonths(timeSavedInMonths % 12);
  };

  useEffect(() => {
    const defaultLoanAmount = props.broker ? '4000' : '3000';
    setLoanAmount(
      (typeof window !== 'undefined' && sessionStorage.getItem('loanAmount')) ??
      defaultLoanAmount
    );
    setFrequency(
      (typeof window !== 'undefined' && sessionStorage.getItem('frequency')) ??
        LOAN_PAY_FREQUENCY.MONTHLY
    );
    setRepaymentPeriod(
      (typeof window !== 'undefined' &&
        sessionStorage.getItem('repaymentPeriod')) ??
        '5'
    );
    setBrokerFee(
      (typeof window !== 'undefined' &&
        sessionStorage.getItem('brokerFee')) ??
      brokerFee ?? 0
    );
    const brokerFeeFloat = brokerFee ? parseFloat(brokerFee) : 0;

    const establishmentFee = props.isGem ? PL_SETTLEMENT_FEE_GEM :
      loanAmount < PL_SP_FEE_AMOUNT ? PL_SETTLEMENT_FEE_SP : PL_SETTLEMENT_FEE;
    const principle = parseFloat(loanAmount) + establishmentFee + brokerFeeFloat;
    const monthlyFee = props.isGem ? 0 : PL_MONTHLY_FEE;
    const {
      repayment,
      totalInterest,
      totalAmountPaid,
      timeSavedByExtra,
      interestSavedByExtra
    } = getRepaymentFigures(
      principle,
      parseFloat(interestRate),
      parseFloat(repaymentPeriod),
      frequency,
      monthlyFee,
      extraRepayments,
      true,
      true
    );

    setRepaymentAmount(repayment);
    setTotalInterestPaid(totalInterest);
    setTotalAmountPaid(totalAmountPaid);
    setInterestSaved(interestSavedByExtra);
    setTimeSavedValues(timeSavedByExtra);
  }, [
    loanAmount,
    frequency,
    repaymentPeriod,
    repaymentAmount,
    totalAmountPaid,
    totalInterestPaid,
    interestSaved,
    extraRepayments,
    widgetValues,
    brokerFee
  ]);

  return (
    <StyledCalculatorFlex>
      <StyledWidgetContainer>
        <EstimateRateWidget
          result
          setWidgetValues={setWidgetValues}
          broker={props.broker}
          brokerFee={props.brokerFee}
          setBrokerFee={setBrokerFee}
          isGem={props.isGem}
          isBetterStart={props.isBetterStart}
        />
      </StyledWidgetContainer>
      <CalculatorTotalsContainer>
        <CalculatorTotals
          salesMode={true}
          repaymentPeriod={frequency}
          repaymentAmount={repaymentAmount}
          totalInterestPaid={totalInterestPaid}
          totalAmountPaid={totalAmountPaid}
          timeSavedMonths={timeSavedMonths}
          timeSavedYears={timeSavedYears}
          interestSaved={interestSaved}
          extraRepayments={extraRepayments}
          setExtraRepayments={setExtraRepayments}
          feesAndCharges
          broker={props.broker}
          isGem={props.isGem}
          isBetterStart={props.isBetterStart}
        />
      </CalculatorTotalsContainer>
    </StyledCalculatorFlex>
  );
};

export default SoftQuoteRepayments;
