export const SITE_URL = 'https://www.latitudefinancial.com.au';

export const SOFTQUOTE_METADATA = {
  title:
    'Find out your Personal Loan interest rate | Latitude Financial Services',
  description:
    'Use our quick & easy online quote tool to calculate your Personal Loan interest rate and repayments. It won’t impact your credit score.',
  keywords: '',
  canonical: ''
};

export const SOFTQUOTE_NZ_METADATA = {
  title: 'What’s my Interest Rate? Get a Quick Quote for your Loan | Gem',
  description:
    'Use our quick & easy online quote tool to calculate your Personal Loan interest rate and repayments. It won’t impact your credit score.',
  keywords: '',
  canonical: ''
};
