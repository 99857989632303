// validation rules and messaging: https://di.latitudefinancial.com/wiki/pages/viewpage.action?spaceKey=LFS&title=AU+Soft+quote+Digital-+SF+Mapping

import { differenceInYears, parseISO, isValid as isDateValid } from 'date-fns';
import { validateSelect } from '@/components/PersonalLoanSoftQuote/validator/addressValidator';

const nameExpr = /^([A-Za-z]+[-' ]?)*[A-Za-z]$/;
const emailExpr = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // Email RegEx has been taken from https://emailregex.com/
export const DAY_REGEX = /^\d{1,2}$/;
export const MONTH_REGEX = /^\d{1,2}$/;
export const YEAR_REGEX = /^\d{1,4}$/;
const auMobileExp = /^04[0-9]{8}/;
const auDriverLicence = /^[0-9a-zA-Z]{1,9}$/;

export function validateName({ minLength, maxLength, fieldName }) {
  return value => {
    const nameLength = value.trim().length;
    const errors = [];

    if (
      (minLength && nameLength < minLength) ||
      (maxLength && nameLength > maxLength)
    ) {
      errors.push(
        `Enter a ${fieldName} between ${minLength}-${maxLength} characters`
      );
    }

    if (!nameExpr.test(value)) {
      errors.push(`Please use letters, hyphens and apostrophes only`);
    }
    return errors.length > 0 ? errors : null;
  };
}

export function validateMiddleName({ maxLength }) {
  return value => {
    const nameLength = value.trim().length;
    const errors = [];

    if (nameLength < 1) {
      return null;
    }

    if (maxLength && nameLength > maxLength) {
      errors.push(`Enter a middle name between 1-14 characters`);
    }
    if (nameLength > 0 && !nameExpr.test(value)) {
      errors.push(`Please use letters, hyphens and apostrophes only`);
    }

    return errors.length > 0 ? errors : null;
  };
}

export const dateOfBirthValidation = ({ day, month, year }) => {
  const errors = [];
  if (DAY_REGEX.test(day)) {
    const dayInt = parseInt(day, 10);

    if (dayInt < 1 || dayInt > 31) {
      errors.push('Day must be within 1-31');
    }
  } else {
    errors.push('Enter a valid day');
  }

  if (MONTH_REGEX.test(month)) {
    const monthInt = parseInt(month, 10);

    if (monthInt < 1 || monthInt > 12) {
      errors.push('Month must be within 1-12');
    }
  } else {
    errors.push('Enter a valid month');
  }

  if (YEAR_REGEX.test(year)) {
    const yearInt = parseInt(year, 10);

    if (yearInt < 1800) {
      errors.push('Enter a valid year');
    }
  } else {
    errors.push('Enter a valid year');
  }

  const twoDigitsDay = day.length === 1 ? `0${day}` : day;
  const twoDigitsMonth = month.length === 1 ? `0${month}` : month;

  const formattedDate = `${year}-${twoDigitsMonth}-${twoDigitsDay}`;
  const diff = differenceInYears(new Date(), new Date(formattedDate));
  if (diff < 18) {
    errors.push('You must be 18 or over to get a quote');
  }

  if (
    isDateValid(parseISO(`${year}-${twoDigitsMonth}-${twoDigitsDay}`)) === false
  ) {
    errors.push('Invalid date.');
  }

  return errors.length > 0 ? errors : null;
};

export function validateMobile() {
  return value => {
    const auMobileLength = value.trim().length;

    if (auMobileLength > 10 || !auMobileExp.test(value)) {
      return 'Enter a 10-digit mobile phone number starting with 04, no spaces';
    }

    return null;
  };
}

export function auDriverLicenceValidation(noDriverLicence) {
  return licenceNumber => {
    if (noDriverLicence) {
      return null;
    }
    if (!auDriverLicence.test(licenceNumber)) {
      return "Enter a valid driver’s licence between 1-9 characters";
    }
    return null;
    /**
     * Equifax highly recommends implementing the following restrictions on drivers licence number entry. See lm-3987 for details.
     * This code has been unit tested and implements Equifax's highly recommended rules, but was out of scope for lm-3985:
     *
    if (!/^[A-Za-z0-9]{4,9}$/.test(licenceNumber)) { // length and alphanumeric
      return "Enter a valid driver's licence between 4 and 9 characters. If you don't have one, tick the 'I don't have a driver's licence' checkbox";
    }
    if (!/^..[0-9]{2}/.test(licenceNumber)) { // 3rd+4th are numeric
      return "The 3rd and 4th characters of a valid driver's licence number must be numeric";
    }
    if (!/(.*[0-9]){4}/.test(licenceNumber)) { // at least 4 numeric
      return "At least four of the characters in a drivers licence number must be numeric";
    }
    if (/(.*[A-Za-z]){3}/.test(licenceNumber)) { // no more than 2 alpha
      return "No more than 2 of the characters in a drivers licence number must be letters";
    }
    return null;
    */
  }
}

export function validateEmail({ maxLength }) {
  return value => {
    const errors = [];

    const emailLength = value.trim().length;

    if (emailLength > maxLength) {
      errors.push(
        `Enter an email address no longer than ${maxLength} characters`
      );
    }

    if (!emailExpr.test(value)) {
      errors.push('Enter a valid email address (eg. name@example.com)');
    }

    return errors.length > 0 ? errors : null;
  };
}

export const titleValidation = validateSelect(`Select a title`);

export const firstNameValidation = validateName({
  minLength: 3,
  maxLength: 14,
  fieldName: 'first name'
});
export const middleNameValidation = validateMiddleName({ maxLength: 15 });
export const lastNameValidation = validateName({
  minLength: 3,
  maxLength: 19,
  fieldName: 'last name'
});
export const emailValidation = validateEmail({ maxLength: 40 });
export const mobileValidation = validateMobile();
export const genderValidation = validateSelect(`Select a gender`);
