// Remember when making changes to this in dev to restart the server
module.exports = {
  utagProfile: 'kiwi-web',
  siteData: {
    page: {
      site: 'kiwi-web',
      siteCountry: 'nz'
    }
  },
  pageDataExceptions: [
    // brokers
    {
      pathMatch: '/broker/',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Broker Details'
    },
    {
      pathMatch: '/Check your rate/broker-details-server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/about-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'About'
    },
    {
      pathMatch: '/Check your rate/about-you-joint-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'About-Joint Application'
    },
    {
      pathMatch: '/Check your rate/about-you-sibling-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'About-Existing Loan'
    },
    {
      pathMatch: '/Check your rate/about-you-server-error-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/employment-server-error-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/employment-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Employment'
    },
    {
      pathMatch: '/Check your rate/quote-success-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Success'
    },
    {
      pathMatch: '/Check your rate/quote-referred-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Referred'
    },
    {
      pathMatch: '/Check your rate/quote-declined-broker',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'brokers',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Declined'
    },

    // au
    {
      pathMatch: '/au/',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'About'
    },
    {
      pathMatch: '/Check your rate/about',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'About'
    },
    {
      pathMatch: '/Check your rate/about-you-joint',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'About-Joint Application'
    },
    {
      pathMatch: '/Check your rate/about-you-sibling',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'About-Existing Loan'
    },
    {
      pathMatch: '/Check your rate/about-you-referred',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Referred'
    },
    {
      pathMatch: '/Check your rate/about-you-server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/employment-server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/employment',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Employment'
    },
    {
      pathMatch: '/Check your rate/quote-success',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Success'
    },
    {
      pathMatch: '/Check your rate/quote-referred',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Referred'
    },
    {
      pathMatch: '/Check your rate/quote-declined',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Declined'
    },
    {
      pathMatch: '/au/retrieve/',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval'
    },
    {
      pathMatch: '/Check your rate/retrieve-verify',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval-Verify'
    },
    {
      pathMatch: '/Check your rate/retrieve-duplicate',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Duplicate'
    },
    {
      pathMatch: '/Check your rate/retrieve-converted-to-app',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Converted-to-Application'
    },
    {
      pathMatch: '/Check your rate/retrieve-success',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Success'
    },
    {
      pathMatch: '/Check your rate/retrieve-expired',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval-Expired'
    },

    // nz
    {
      pathMatch: '/nz/',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'About'
    },
    {
      pathMatch: '/Check your rate/about-you-joint-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'About-Joint Application'
    },
    {
      pathMatch: '/Check your rate/about-you-sibling-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'About-Existing Loan'
    },
    {
      pathMatch: '/Check your rate/about-you-referred-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Referred'
    },
    {
      pathMatch: '/Check your rate/about-you-server-error-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/employment-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Employment'
    },
    {
      pathMatch: '/Check your rate/employment-server-error-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Server-Error'
    },
    {
      pathMatch: '/Check your rate/quote-success-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Success'
    },
    {
      pathMatch: '/Check your rate/quote-referred-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Referred'
    },
    {
      pathMatch: '/Check your rate/quote-declined-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Declined'
    },
    {
      pathMatch: '/nz/retrieve/',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval',
    },
    {
      pathMatch: '/Check your rate/retrieve-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval-Verify'
    },
    {
      pathMatch: '/Check your rate/retrieve-duplicate-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Duplicate'
    },
    {
      pathMatch: '/Check your rate/retrieve-converted-to-app-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Converted-to-Application'
    },
    {
      pathMatch: '/Check your rate/retrieve-success-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieve-Success'
    },
    {
      pathMatch: '/Check your rate/retrieve-expired-gem',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate',
      pagetitle: 'Quote-Retrieval-Expired'
    },
    // TODO: for cleanup
    {
      pathMatch: '/loans/soft-quote/about-you',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote/about-you/referred',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote/about-you/server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote/employment',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote/employment/server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },

    // retrieve
    {
      pathMatch: '/loans/soft-quote-retrieve/',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-retrieve/verify',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-retrieve/expired',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-retrieve/server-error',
      productid: ['PLAULF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'latitude',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-retrieve/approved',
      productid: ['PLAULF-WEB']
    },

    // soft quote - gem
    {
      pathMatch: '/loans/soft-quote-gem/about-you',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem/about-you/referred',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem/about-you/server-error',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem/employment',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem/employment/server-error',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },

    // gem retrieve - gem
    {
      pathMatch: '/loans/soft-quote-gem-retrieve/',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem-retrieve/verify',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem-retrieve/expired',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem-retrieve/server-error',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    },
    {
      pathMatch: '/loans/soft-quote-gem-retrieve/approved',
      productid: ['PLNZLF-WEB'],
      pagetype: 'quote-page',
      sitesection: 'gem',
      subsection: 'Check your rate'
    }
  ]
};
