import { differenceInYears } from 'date-fns';
import {
  REGION,
  SALESFORCE_ANALYTICS_OUTCOME_STATUS_MAP,
  SALESFORCE_STATUS
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import tealiumConfig from '../../tealium';

const applyPageExceptions = (analytics, path) => {
  if (analytics && analytics.pageData) {
    const exceptions = tealiumConfig.pageDataExceptions.find(item => item.pathMatch === path);
    if (exceptions) {
      analytics.pageData.page_pageType = exceptions.pagetype;
      analytics.pageData.page_siteSection = exceptions.sitesection;
      analytics.pageData.page_siteSubSection = exceptions.subsection;
      analytics.pageData.page_pageTitle = exceptions.pagetitle;
      analytics.pageData.product_productid = exceptions.productid;
    }
  }
}

export const dataLayerOnFirstPageLoad = (analytics) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};
  if (!analytics.siteData.quote.quoteStartDate) {
    analytics.siteData.quote.quoteStartDate = (new Date()).toISOString();
    analytics.siteData.quote.quoteStatus = "Started";
    analytics.siteData.quote.quoteRetrieved = "no";
  }
}

export const dataLayerOnAboutState = (analytics, state, region = REGION.AU) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.page = analytics.siteData.page || {};
  analytics.siteData.quote = analytics.siteData.quote || {};

  if (analytics.siteData.quote.numberOfApplicants != state.values.numberOfPeopleRequesting
    || analytics.siteData.quote.currentLoanExists != state.values.sibling) {
    analytics.siteData.quote.numberOfApplicants = state.values.numberOfPeopleRequesting;
    analytics.siteData.quote.currentLoanExists = state.values.sibling;

    if (state.values.sibling === 'no' && state.values.numberOfPeopleRequesting === '1 person') {
      applyPageExceptions(analytics, `/${region.toLowerCase()}/`);
      delete analytics.siteData.quote.quoteOutcome;
      delete analytics.siteData.quote.quoteOutcomeReason;

    } else if (state.values.sibling === 'yes') {
      applyPageExceptions(analytics, '/Check your rate/about-you-sibling' + (region == REGION.NZ ? "-gem" : ""))
      analytics.siteData.quote.quoteOutcome = QUOTE_OUTCOME.Unquoted;
      analytics.siteData.quote.quoteOutcomeReason = "ExistingPersonalLoan";

    } else if (state.values.numberOfPeopleRequesting === '2 people') {
      applyPageExceptions(analytics, '/Check your rate/about-you-joint' + (region == REGION.NZ ? "-gem" : ""))
      analytics.siteData.quote.quoteOutcome = QUOTE_OUTCOME.Unquoted;
      analytics.siteData.quote.quoteOutcomeReason = "JointApplication";
    }
  }
  return true;
}

const QUOTE_OUTCOME = {
  Unquoted: "Unquoted"
};

export const dataLayerOnAboutSubmit = (analytics, widgetValues, values ) => {
    analytics.siteData = analytics.siteData || {};
    analytics.siteData.quote = analytics.siteData.quote || {};
    analytics.siteData.applicant = analytics.siteData.applicant || {};
    analytics.siteData.applicant.personal = analytics.siteData.applicant.personal || {};
    analytics.siteData.quote.loanamountrequested = widgetValues.loanAmount;
    analytics.siteData.quote.loanpurpose = widgetValues.purpose;
    analytics.siteData.quote.loanDuration = widgetValues.repaymentPeriod;
    analytics.siteData.quote.numberOfApplicants = values.numberOfPeopleRequesting;
    analytics.siteData.quote.currentLoanExists = values.sibling;
    analytics.siteData.applicant.personal.title = values.title;
    analytics.siteData.applicant.personal.firstName = values.firstName;
    analytics.siteData.applicant.personal.mobileNumber = values.mobileNumber;
    analytics.siteData.applicant.personal.gender = values.gender;
    analytics.siteData.applicant.personal.emailAddress = values.email;
    analytics.siteData.applicant.personal.hasDriverLicence =
      (/* au */ values.hasDriversLicence == "no" || /* nz */ values.hasDriverLicence == "no") ? "no" :
      (/* au */ values.hasDriversLicence == "yes" || /* nz */ values.hasDriverLicence == "yes") ? "yes" :
      undefined;
    analytics.siteData.applicant.personal.age = String(
      age(values.dateOfBirth)
    );
};

export const dataLayerOnEmploymentSubmit = (analytics, values, quoteIdInternal, formattedData) => {
  analytics.siteData = analytics.siteData || {};

  analytics.siteData.quote = analytics.siteData.quote || {};
  analytics.siteData.quote.quoteIdInternal = quoteIdInternal;
  analytics.siteData.quote.loanPurpose = sessionStorage.getItem('purpose');
  analytics.siteData.quote.loanAmountRequested = sessionStorage.getItem('loanAmount');
  analytics.siteData.quote.loanDuration = sessionStorage.getItem('repaymentPeriod');

  analytics.siteData.applicant = analytics.siteData.applicant || {};
  analytics.siteData.applicant.personal = analytics.siteData.applicant.personal || {};
  analytics.siteData.applicant.personal.residentialStatus = values.residentialStatus;
  analytics.siteData.applicant.employment = analytics.siteData.applicant.employment || {};
  analytics.siteData.applicant.employment.employmentStatus = values.incomeFrom;
  if (values.occupation) {
    analytics.siteData.applicant.employment.industryType = values.occupation;
  }
  analytics.siteData.applicant.address = analytics.siteData.applicant.address || {};
  analytics.siteData.applicant.address.postCode = formattedData.Residential_Postcode__c;
  if (formattedData.Residential_Suburb__c) {
    analytics.siteData.applicant.address.suburb = formattedData.Residential_Suburb__c;
  }
  if (formattedData.Residential_State__c) {
    analytics.siteData.applicant.address.state = formattedData.Residential_State__c;
  }
  if (formattedData.Residential_City__c) {
    analytics.siteData.applicant.address.city = formattedData.Residential_City__c;
  }
  if (analytics.siteData.broker) {
    analytics.siteData.broker.brokerfee = sessionStorage.getItem('brokerFee') === '' ? '0' : sessionStorage.getItem('brokerFee');
  }
}

export const dataLayerOnQuoteResponse = (analytics, values) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};
  analytics.siteData.quote.quoteId = values.quoteNumber;
  analytics.siteData.quote.quoteStatus = "Complete";
  if (values.quoteRate) {
    analytics.siteData.quote.quoteInterestRate = String(values.quoteRate);
  }
  analytics.siteData.quote.quoteOutcome = SALESFORCE_ANALYTICS_OUTCOME_STATUS_MAP.get(
    values.quoteStatus
  );
};

export const dataLayerOnBrokerDetailsSubmit = (analytics, values ) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.broker = analytics.siteData.broker || {};
  analytics.siteData.broker.brokerid = values.brokerNumber;
};

const age = (dateOfBirth) => {
  const twoDigitsDay = dateOfBirth.day.length === 1 ? `0${dateOfBirth.day}` : dateOfBirth.day;
  const twoDigitsMonth = dateOfBirth.month.length === 1 ? `0${dateOfBirth.month}` : dateOfBirth.month;

  const formattedDate = `${dateOfBirth.year}-${twoDigitsMonth}-${twoDigitsDay}`;
  return differenceInYears(new Date(), new Date(formattedDate));
}

export const dataLayerOnAboutYouReferred = (analytics) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};
  analytics.siteData.quote.quoteStatus = "Complete";
  analytics.siteData.quote.quoteOutcome = SALESFORCE_ANALYTICS_OUTCOME_STATUS_MAP.get(SALESFORCE_STATUS.Referred);

  return true;
}

export const dataLayerOnQuoteRetrieveStart = (analytics) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};

  if (!analytics.siteData.quote.quoteRetrieved) {
    analytics.siteData.quote.quoteRetrieved = "no";
  }
  return true;
}

export const dataLayerOnQuoteRetrieveSendCode = (analytics, values) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};

  analytics.siteData.applicant = analytics.siteData.applicant || {};
  analytics.siteData.applicant.personal = analytics.siteData.applicant.personal || {};
  analytics.siteData.applicant.personal.emailAddress = values.emailAddress;
  analytics.siteData.applicant.personal.age = String(
    age(values.dateOfBirth)
  );
  return true;
}

export const dataLayerOnQuoteRetrieved = (analytics, userData) => {
  analytics.siteData = analytics.siteData || {};
  analytics.siteData.quote = analytics.siteData.quote || {};
  analytics.siteData.quote.quoteRetrieved = "yes";
  analytics.siteData.quote.quoteId = userData.quoteNumber;
  if (userData.quoteId) {
    analytics.siteData.quote.quoteIdInternal = userData.quoteId;
  }
  analytics.siteData.quote.quoteInterestRate = userData.interestRate;
  analytics.siteData.quote.loanamountrequested = userData.loanAmount;
  analytics.siteData.quote.loanpurpose = userData.loanpurpose;
  analytics.siteData.quote.loanDuration = userData.loanTerm;
  return true;
}
