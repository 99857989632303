import React from 'react';
import { Container, Grid, Text } from 'basis';
import { Link as ButtonLink } from '@latitude/link';

export default props => {
  const isGem = props.isGem;
  return (
    <Container padding="4">
      <Grid rowsGap={4}>
        <Text textStyle="heading4" align="center">
          Need help? Call us
        </Text>
        <div css="display:block; text-align:center;">
          <ButtonLink
            button="tertiary"
            href={isGem ? 'tel:0800422898' : 'tel:1300973422'}
            css="display:inline-block;"
            trackEventData={{
              location: 'Need help'
            }}
          >
            {isGem ? 'Call 0800 422 898' : 'Call 1300 973 422'}
          </ButtonLink>
        </div>
        <Text align="center">
          {isGem
            ? 'Mon to Fri: 8:30am - 6:30pm (NZST)'
            : 'Mon to Fri: 8am - 7pm (AEST)'}
          <br />
          Weekends: Closed
        </Text>
      </Grid>
    </Container>
  );
};
