import React, { useState } from 'react';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import styled from 'styled-components';
import AnimatedNumber from 'react-animated-number';
import { Link } from '@latitude/link';
import { Button } from '@latitude/button';
import { Heading1, Heading5 } from '../Heading/Heading';
import { formatAsCurrency } from '../../utils';
import {
  MARGIN,
  COLOR,
  JUSTIFY_CONTENT,
  ALIGN,
  FLEX_DIRECTION,
  BREAKPOINT,
  LOAN_PAY_FREQUENCY,
  PADDING,
  ALIGN_ITEMS,
  FONT_SIZE,
  PL_SETTLEMENT_FEE
} from '../../utils/constants';
import { Box, Flex } from '../Box/Box';
import Text from '../Text/Text';
import ExtraRepayments from './ExtraRepayments';
import Modal from '../Modal/Modal';

const repaymentPeriodText = {
  [LOAN_PAY_FREQUENCY.WEEKLY]: 'weekly',
  [LOAN_PAY_FREQUENCY.FORTNIGHTLY]: 'fortnightly',
  [LOAN_PAY_FREQUENCY.MONTHLY]: 'monthly'
};

const animatedNumbersDuration = 300;
const styledArrowW = 36;
const styledArrowH = 22;
const styledArrowBg = COLOR.BLUE_LIGHT_BRIGHT;

const StyledH1 = styled(Heading1)`
  font-size: 80px;
  line-height: 88px;
  margin-bottom: 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-bottom: 24px;
    font-size: 94px;
    line-height: 70px;
    letter-spacing: -2.38px;
  }
`;

const StyledCalculatorDisplay = styled(Flex)`
  position: relative;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  min-height: 300px;
  margin-top: ${MARGIN.M32};

  &::before {
    content: '';
    position: absolute;
    display: block;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    margin-left: -${styledArrowW / 2}px;
    left: 50%;
    top: -1px;
    border: ${styledArrowH}px solid transparent;
    border-left-width: ${styledArrowW / 2}px;
    border-right-width: ${styledArrowW / 2}px;
    border-top-color: ${styledArrowBg};
    border-bottom: none;
  }

  @media (min-width: ${BREAKPOINT.LG}) {
    margin-left: ${MARGIN.M32};
    width: 50%;
    margin-top: 0;

    &::before {
      display: none;
    }
  }
`;

const StyledFlex = styled(Flex)`
  flex-grow: 1;
`;

const AmountsSavedFlex = styled(Flex)`
  flex-grow: 1;

  &::before {
    content: '';
    position: relative;
    display: block;
    height: 1px;
    width: 1px;
    font-size: 0;
    line-height: 0;
    margin-left: -${styledArrowW / 2}px;
    left: 50%;
    border: ${styledArrowH}px solid transparent;
    border-left-width: ${styledArrowW / 2}px;
    border-right-width: ${styledArrowW / 2}px;
    border-top-color: ${COLOR.BLUE};
    border-bottom: none;
  }
`;

const StyledCalculatorTotalsBox = styled(Box)`
  margin: ${MARGIN.M48} 0 ${MARGIN.M16};
  @media (min-width: ${BREAKPOINT.LG}) {
    margin: 0;
  }
`;

const IntroBox = styled(Box)`
  padding: ${PADDING.P16} ${PADDING.P48} 0;

  @media (min-width: ${BREAKPOINT.LG}) {
    padding: ${PADDING.P16} ${PADDING.P16} 0;
  }

  @media (min-width: ${BREAKPOINT.XL}) {
    padding: ${PADDING.P16} ${PADDING.P48} 0;
  }
`;

const StyledHeading5 = styled(Heading5)`
  font-size: 18px;
  line-height: 24px;
`;

const MobileTimeSavedHeading = styled(Heading5)`
  display: block;

  @media (min-width: ${BREAKPOINT.SM}) {
    display: none;
  }
`;

const DesktopTimeSavedHeading = styled(Heading5)`
  display: none;

  @media (min-width: ${BREAKPOINT.SM}) {
    display: block;
  }
`;

const StyledFeesModal = styled(Modal)`
  strong + p {
    margin-top: -5px;
  }
`;

const defaultIntro = (repaymentText, broker, isGem, isBetterStart) => (
  <IntroBox>
    <StyledHeading5 align={ALIGN.CENTER} color={COLOR.WHITE}>
      {isBetterStart
        ? `After ${broker ? 'the' : 'your'} 3 month no payment period, y`
        : `Y`}
      our {broker && `client's `}estimated {repaymentText} repayment including
      establishment
      {isGem
        ? ' fee'
        : broker
        ? `, monthly and brokerage fees`
        : ` & monthly fees`}
    </StyledHeading5>
  </IntroBox>
);

const CalculatorTotals = ({
  salesMode,
  repaymentPeriod,
  repaymentAmount,
  totalInterestPaid,
  totalAmountPaid,
  timeSavedMonths,
  timeSavedYears,
  interestSaved,
  extraRepayments,
  setExtraRepayments,
  applyUrl,
  applyCTATrackId,
  feesAndCharges,
  renderIntro = defaultIntro,
  userInputLoanAmount,
  userInputRepaymentYears,
  broker,
  isGem,
  isBetterStart,
}) => {
  const [showFeesModal, setShowFeesModal] = useState(false);
  const TimeSavedSection = (yearsText, monthsText) => {
    return (
      <>
        {timeSavedYears !== 0 && (
          <>
            <AnimatedNumber
              value={timeSavedYears}
              duration={animatedNumbersDuration}
              stepPrecision={0}
            />
            {timeSavedYears > 1 ? ` ${yearsText}s` : ` ${yearsText}`}
          </>
        )}
        {timeSavedMonths !== 0 && (
          <>
            {timeSavedYears ? ', ' : null}
            <AnimatedNumber
              value={timeSavedMonths}
              duration={animatedNumbersDuration}
              stepPrecision={0}
            />
            {timeSavedMonths > 1 ? ` ${monthsText}s` : ` ${monthsText}`}
          </>
        )}
      </>
    );
  };
  const EstablishmentFee = () => {
    return (
      <p>
        $140 for loans under $5,000
        <br />
        ${PL_SETTLEMENT_FEE} for loans of $5,000 or more
      </p>
    );
  };
  const EstablishmentFeeBetterStart = () => {
    return <p>$250 for loans of $20,000 or more</p>;
  };

  return (
    <StyledCalculatorDisplay flexDirection={FLEX_DIRECTION.COLUMN}>
      <StyledFlex
        backgroundColor={COLOR.BLUE}
        justifyContent={JUSTIFY_CONTENT.CENTER}
        flexDirection={FLEX_DIRECTION.COLUMN}
      >
        <StyledCalculatorTotalsBox>
          {renderIntro(
            repaymentPeriodText[repaymentPeriod],
            broker,
            isGem,
            isBetterStart
          )}
          <StyledH1 align={ALIGN.CENTER} color={COLOR.WHITE}>
            <AnimatedNumber
              value={repaymentAmount}
              duration={animatedNumbersDuration}
              stepPrecision={0}
              formatValue={n => formatAsCurrency(n) || '$0'}
            />
          </StyledH1>
        </StyledCalculatorTotalsBox>
        <ExtraRepayments
          salesMode={salesMode}
          repaymentPeriod={repaymentPeriod}
          extraRepayments={extraRepayments}
          setExtraRepayments={setExtraRepayments}
          broker={broker}
          isGem={isGem}
          isBetterStart={isBetterStart}
        />
      </StyledFlex>
      <AmountsSavedFlex
        backgroundColor={COLOR.BLUE_DEEP}
        flexDirection={FLEX_DIRECTION.COLUMN}
      >
        <Flex alignItems={ALIGN_ITEMS.CENTER} paddingTop={PADDING.P8}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.WHITE}>
              Total interest paid
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <Heading5 marginBottom={0} color={COLOR.WHITE}>
              <AnimatedNumber
                value={totalInterestPaid}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </Heading5>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.WHITE}>
              Total amount paid
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <Heading5 marginBottom={0} color={COLOR.WHITE}>
              <AnimatedNumber
                value={totalAmountPaid}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </Heading5>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.WHITE}>
              Interest saved
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <Heading5 marginBottom={0} color={COLOR.WHITE}>
              <AnimatedNumber
                value={interestSaved}
                duration={animatedNumbersDuration}
                stepPrecision={0}
                formatValue={n => formatAsCurrency(n) || '$0'}
              />
            </Heading5>
          </Box>
        </Flex>
        <Flex alignItems={ALIGN_ITEMS.CENTER}>
          <Box width="50%" marginRight={MARGIN.M8}>
            <Text align={ALIGN.RIGHT} color={COLOR.WHITE}>
              Time saved
            </Text>
          </Box>
          <Box width="50%" marginLeft={MARGIN.M8}>
            <>
              {timeSavedMonths === 0 && timeSavedYears === 0 ? (
                <>
                  <MobileTimeSavedHeading marginBottom={0} color={COLOR.WHITE}>
                    0 yrs, 0 mths
                  </MobileTimeSavedHeading>
                  <DesktopTimeSavedHeading marginBottom={0} color={COLOR.WHITE}>
                    0 years, 0 months
                  </DesktopTimeSavedHeading>
                </>
              ) : (
                <>
                  <MobileTimeSavedHeading marginBottom={0} color={COLOR.WHITE}>
                    {TimeSavedSection('yr', 'mth')}
                  </MobileTimeSavedHeading>
                  <DesktopTimeSavedHeading marginBottom={0} color={COLOR.WHITE}>
                    {TimeSavedSection('year', 'month')}
                  </DesktopTimeSavedHeading>
                </>
              )}
            </>
          </Box>
        </Flex>
      </AmountsSavedFlex>
      <Flex
        backgroundColor={COLOR.BLUE_DEEP}
        justifyContent={JUSTIFY_CONTENT.CENTER}
      >
        {/* Only show apply CTA if not in sales mode */}
        {!salesMode && (
          <Box width="250px" margin={`${MARGIN.M16} 0 ${MARGIN.M24}`}>
            <AnalyticsLocationProvider location="Calculate your repayments">
              <Button
                trackId={applyCTATrackId}
                onClick={() => {
                  // if user has updated the input ampunt or repayment years,
                  // add the values to session storage so they will persist in the `EstimateRateWidget` comnponent
                  if (userInputLoanAmount) {
                    sessionStorage.setItem('loanAmount', userInputLoanAmount);
                  }
                  if (userInputLoanAmount) {
                    sessionStorage.setItem(
                      'repaymentPeriod',
                      userInputRepaymentYears
                    );
                  }
                  if (typeof window !== 'undefined') {
                    window.location = applyUrl;
                  }
                }}
                css="width:100%;"
              >
                Get my rate
              </Button>
            </AnalyticsLocationProvider>
          </Box>
        )}
        {/* Opt to see Fees and Charges note */}
        {feesAndCharges && (
          <Box margin={`${MARGIN.M16} 0 ${MARGIN.M24}`}>
            <Text
              fontSize={FONT_SIZE.SMALL}
              align={ALIGN.CENTER}
              color={COLOR.WHITE}
            >
              See all{' '}
              <Link
                href="#"
                variant={COLOR.WHITE}
                onClick={e => {
                  setShowFeesModal(true);
                  e.preventDefault();
                }}
              >
                Fees &amp; charges
              </Link>{' '}
              for this product
            </Text>
            <StyledFeesModal
              isOpen={showFeesModal}
              onRequestClose={() => setShowFeesModal(false)}
              title="Fees & Charges"
              content={
                <Box>
                {isGem ? (
                  <>
                    <strong>Establishment Fee</strong>
                    <p>$240</p>
                    <strong>NZ Postshop Payment Handling Fee</strong>
                    <p>$1.50</p>
                    <strong>Late Payment Fee</strong>
                    <p>
                      $35 In the event that you can’t make the minimum
                      repayment by your agreed due date
                    </p>
                    <strong>Early Repayment Fee</strong>
                    <p>
                      No early repayment fee, so you can pay your loan off
                      sooner at no extra charge!
                    </p>
                  </>
                ) : (
                  <>
                    <strong>Establishment Fee</strong>
                    {isBetterStart ? (
                      <EstablishmentFeeBetterStart />
                    ) : (
                      <EstablishmentFee />
                    )}
                    <strong>Loan Service Fee</strong>
                    <p>$13 per month</p>
                    <strong>Australia Post Payment Handling Fee</strong>
                    <p>$1.50</p>
                    <strong>BPAY® Payment Handling Fee</strong>
                    <p>$1.50</p>
                    <strong>Late Payment Fee</strong>
                    <p>
                      $35 In the event that {broker ? 'your client' : 'you'}{' '}
                      can’t make the minimum repayment by{' '}
                      {broker ? 'their' : 'your'} agreed due date
                    </p>
                    <strong>Early Termination Fee</strong>
                    <p>
                      $300 within the first half of{' '}
                      {broker ? 'their' : 'your'} loan term{' '}
                      {isBetterStart
                        ? ' (inclusive of the 3 month no payment period)'
                        : ''}
                      <br />
                      $0 within the second half of {broker
                        ? 'their'
                        : 'your'}{' '}
                      loan term
                    </p>
                    <strong>Extra Payments</strong>
                    <p>
                      With a Latitude {isBetterStart && 'Better Start '}
                      Personal Loan, {broker ? 'your client' : 'you'} can make
                      extra payments whenever and however{' '}
                      {broker ? 'they' : 'you'} like. Be aware that if{' '}
                      {broker ? 'they' : 'you'} pay off
                      {broker ? ' their' : ' your'} loan before{' '}
                      {broker ? 'they' : 'you'}&apos;re halfway through{' '}
                      {broker ? ' their' : ' your'} original term
                      {isBetterStart &&
                        ' (inclusive of the 3 month no payment period)'}
                      ,{broker ? ' they' : ' you'} will be charged an early
                      termination fee of $300. Payment handling fee of $1.50
                      apply when making extra payments via BPAY®
                    </p>
                  </>
                  )}
                </Box>
              }
              ariaHideApp={false}
            />
          </Box>
        )}
      </Flex>
    </StyledCalculatorDisplay>
  );
};

export default CalculatorTotals;
