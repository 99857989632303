import React, { useContext } from 'react';
import { AnalyticsContext, trackEvent } from 'latitude-analytics';
import {
  BasisProvider,
  defaultTheme,
  Container,
  Button,
  Stack,
  Grid,
  Text
} from 'basis';
import styled from 'styled-components';
import TitleText from './partials/TitleText';
import NeedHelpSection from './partials/NeedHelpSection';

export const ServerError = ({ hideTryAgain, broker, isGem }) => (
  <BasisProvider theme={defaultTheme}>
    <Container padding="4">
      <ServerErrorContent
        hideTryAgain={hideTryAgain}
        broker={broker}
        isGem={isGem}
      />
    </Container>
  </BasisProvider>
);

export const ServerErrorContent = props => {
  const [analytics] = useContext(AnalyticsContext);
  if (props.isGem) analytics.siteData.page.siteCountry = 'nz';

  return (
    <Stack align="center" gap="4">
      <TitleText title="Something went wrong" />
      <ServerErrorTextContainer>
        <Text align="center">
          Looks like the server is taking too long to respond. Please try again
          in a few minutes.
        </Text>
      </ServerErrorTextContainer>
      {props.hideTryAgain !== true && (
        <Button
          type="submit"
          color="green"
          onClick={() => {
            trackEvent(analytics, {
              category: 'quote',
              action: 'quote-link',
              label: 'Try again',
              location: 'Server Error'
            });
          }}
        >
          Try again
        </Button>
      )}
      {props.broker !== true && <NeedHelpSection isGem={props.isGem} />}
      {props.broker && <NeedHelpBrokerSection />}
      <DogImage />
    </Stack>
  );
};

export const NeedHelpBrokerSection = () => {
  const [analytics] = useContext(AnalyticsContext);
  return (
    <Container padding="4 4 2 4">
      <Grid rowsGap={4}>
        <Text textStyle="heading4" align="center">
          Need help? Call us
        </Text>
        <div css="display:block; text-align:center;">
          <Button
            variant="secondary"
            onClick={() => {
              trackEvent(analytics, {
                category: 'contact-us',
                action: 'phone-link',
                label: 'Call 1800 035 902',
                location: 'Need Help'
              });

              if (typeof window !== 'undefined') {
                window.location = 'tel:1800035902';
              }
            }}
          >
            Call 1800 035 902
          </Button>
        </div>
        <Text align="center">
          Mon to Fri: 8am - 6pm (AEST) <br />
          Weekends: Closed
        </Text>
      </Grid>
    </Container>
  );
};

const ServerErrorTextContainer = styled.div`
  max-width: 350px;
`;

const DogImage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 610 196"
    css="width:100%; max-width:380px;"
  >
    <defs>
      <path
        id="prefix__a"
        d="M0.723 0.02L179.485 0.02 179.485 138 0.723 138z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#174BA0"
          fillRule="nonzero"
          d="M564.957 9.031c-2.225 1.634-5.354 1.153-6.987-1.073-1.635-2.226-1.154-5.354 1.072-6.99 2.227-1.633 5.356-1.152 6.99 1.075 1.633 2.225 1.152 5.356-1.075 6.988"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#FF94CA"
          fillRule="nonzero"
          d="M569.958 37.032c-2.226 1.632-5.355 1.152-6.988-1.074-1.635-2.226-1.154-5.356 1.073-6.99 2.226-1.633 5.355-1.152 6.988 1.075 1.634 2.224 1.153 5.355-1.073 6.989"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#BCB9F8"
          fillRule="nonzero"
          d="M595.958 41.031c-2.225 1.634-5.355 1.153-6.988-1.073-1.635-2.225-1.154-5.355 1.073-6.99 2.226-1.632 5.356-1.151 6.989 1.076 1.632 2.223 1.152 5.355-1.074 6.987"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#F5B1CF"
          fillRule="nonzero"
          d="M0 0.5L20.271 7.941"
          transform="translate(0 -6) translate(0 14) translate(594 26.5)"
        />
        <path
          fill="#FEFEFE"
          fillRule="nonzero"
          d="M23 133.5c.035 6.465.07 11.96.103 18.425.016 2.795.17 5.933 2.155 7.815 1.796 1.702 4.477 1.777 6.907 1.758 1.85-.012 4.105-.292 4.835-2.059-3.85-.527-5.993-5.092-6.095-9.128-.1-4.034 1.121-8.03.832-12.057-.04-.567-.124-1.164-.462-1.61-.42-.549-1.127-.747-1.785-.908l-5.039-1.255"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M21 134.149l.092 15.168c.02 3.453-.381 7.642 1.802 10.618 3.119 4.255 12.781 5.057 15.864.451.599-.891.023-2.277-1.04-2.506-7.675-1.67-4.301-12.019-4.155-17.113.172-5.997-3.614-6.076-8.854-7.305-2.177-.511-3.107 2.78-.926 3.293 1.259.295 4.243.44 5.224 1.228 1.772 1.42 1.003 4.217.781 6.055-.321 2.663-.82 5.32-.46 8.009.567 4.21 3.034 8.16 7.464 9.125-.348-.836-.695-1.671-1.041-2.51-1.318 1.97-7.24 1.384-8.94.302-2.668-1.696-2.21-5.594-2.23-8.232-.041-5.527-.067-11.056-.1-16.583-.012-2.196-3.493-2.2-3.481 0"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#FEFEFE"
          fillRule="nonzero"
          d="M41 140.344l.009 17.955c.001 1.054.026 2.192.662 3.034.741.985 2.078 1.275 3.3 1.46 2.54.385 5.103.616 7.67.694 2.419.074 5.119-.08 6.303-2.886.05-.117.067-.26.05-.385-.48-3.31-2.855-2.182-4.349-3.118-.92-.578-1.23-1.75-1.427-2.816-.76-4.113-.986-8.325-.668-12.496-3.776-1.048-7.63-1.814-11.52-2.286"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M39.156 140.1l.006 12.923c.002 2.694-.663 6.324.686 8.807 2.223 4.092 11.356 3.898 15.278 3.429 3.533-.423 7.642-3.735 5.069-7.482-1.075-1.569-2.436-1.074-3.78-1.918-3.177-1.998-2.183-10.996-1.98-14.293.046-.746-.573-1.473-1.28-1.661-4.028-1.076-8.09-1.848-12.226-2.388-2.218-.29-2.195 3.163 0 3.45 3.83.5 7.573 1.27 11.3 2.263-.427-.554-.854-1.108-1.28-1.664-.28 4.534-.44 9.86 1.004 14.25.4 1.218 1.12 2.297 2.268 2.923.971.53 4.343.372 2.203 2.658-1.037 1.108-4.957.457-6.257.366-4.205-.298-6.793-.005-7.515-4.938-.78-5.315-.006-11.346-.01-16.725 0-2.22-3.487-2.223-3.486 0M68.25 144.406c29.486 4.715 59.319 5.73 89.126 4.76 7.43-.243 14.858-.597 22.28-1.014 5.007-.284 10.18-.266 15.141-1 5.599-.833 11.171-2.564 16.534-4.319 13.61-4.45 26.62-10.623 38.848-18.078 1.898-1.156.164-4.155-1.743-2.992-16.23 9.894-34.729 18.64-53.631 21.876-3.556.61-7.245.578-10.84.794-7.182.43-14.365.806-21.552 1.084-14.217.556-28.452.737-42.675.276-16.939-.552-33.83-2.053-50.57-4.731-2.174-.347-3.11 2.993-.918 3.344M154.63 98.417c10.582-.076 21.42.465 31.965-.505 9.837-.904 19.203-4.958 27.581-10.109 1.903-1.17.166-4.214-1.745-3.038-8.157 5.016-17.49 9.195-27.148 9.726-11.193.619-22.51.361-33.717.428-29.637.174-59.273-.19-88.897-1.066-2.226-.067-2.225 3.452 0 3.518 30.645.906 61.305 1.263 91.962 1.046z"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M67.335 141.04c-9.364-.413-18.56-2.195-27.72-4.089-4.05-.834-8.206-1.47-12.181-2.615-5.235-1.506-8.775-4.547-11.677-9.087C7.53 112.383-5.26 88.575 11.619 76.78l-2.535-1.953c-1.146 7.778-2.865 16.126.106 23.709 1.661 4.242 5.33 7.491 9.994 5.292 3.476-1.64 6.592-3.4 10.371-4.39 4.064-1.061 8.239-1.6 12.424-1.871 7.873-.504 15.768-.054 23.634.341 2.222.11 2.216-3.348 0-3.462-12.782-.64-26.198-1.622-38.63 2.124-2.64.797-5.23 1.83-7.653 3.152-1.965 1.073-4.185 2.145-5.886-.243-4.56-6.405-2.07-16.675-1.03-23.732.194-1.32-1.158-2.917-2.538-1.954C2.926 78.652-.239 86.893.014 95.237c.329 10.799 6.159 20.682 11.516 29.72 2.278 3.844 4.874 7.629 8.723 10.053 4.255 2.678 9.36 3.41 14.193 4.4 10.879 2.227 21.762 4.598 32.89 5.088 2.221.099 2.217-3.363 0-3.459M454.44 137.741c-1.585 9.909-2.756 19.854-3.287 29.874-.039.736.569 1.472 1.282 1.652 1.351.343 4.667.493 5.133 2.205.56-.725 1.123-1.447 1.682-2.17-3.077.376-6.952.996-10.052.369-1.052.19-1.894-.137-2.524-.981-1.107-.403-1.44-1.137-.996-2.2-.224-1.367.176-3.102.226-4.496.21-5.94.002-11.873-.33-17.804-.122-2.195-3.613-2.208-3.489 0 .237 4.258.423 8.52.419 12.785-.005 3.866-.717 7.945-.44 11.781.48 6.709 12.865 4.502 17.186 3.974 1.11-.137 2.006-.987 1.684-2.172-.981-3.6-4.374-3.786-7.571-4.595l1.282 1.652c.514-9.714 1.624-19.354 3.162-28.964.344-2.158-3.019-3.086-3.366-.91"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M426.614 146.253c2.017-.211 3.106-.15 4.252 1.506 1.132 1.635 1.333 3.841 1.415 5.76.187 4.381-.052 8.818-.09 13.204-.024 2.806-.484 6.024 2.16 7.766 1.921 1.264 5.284 1.25 7.392.47 2.137-.791 2.908-2.619 3.197-4.758.578-4.25.414-8.74.603-13.025.165-3.753 2.233-14.258-4.036-14.672-2.172-.143-2.162 3.27 0 3.414 2.048.134.403 17.055.316 19.02-.063 1.405-.064 2.83-.187 4.233-.181 2.066-2.554 3.816-4.695 2.738-2.534-1.277-1.347-7.91-1.328-10.15.061-6.998 1.328-19.996-8.999-18.918-2.141.223-2.163 3.638 0 3.412"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M338.668 147.498c22.398-.92 44.814-.843 67.224-.948 10.947-.051 21.943.046 32.883-.459 7.684-.358 15.36-1.88 19.93-8.641l-1.492.857c12.707-1.082 17.409-17.653 24.525-25.845 2.197-2.53 4.764-4.802 7.913-6.048 3.87-1.53 7.925-.733 11.954-1.188 4.797-.538 9.906-3.974 9.214-9.348-.093-.72-.467-1.507-1.27-1.666-3.417-.686-6.295-2.04-8.925-4.35v2.446c6.942-6.622 14.094-13.081 20.834-19.91 4.441-4.498 8.588-10.69 5.437-17.107-2.81-5.723-8.798-6.056-13.46-2.394-2.758 2.167-4.933 4.835-8.495 5.686-3.812.912-7.928-.125-11.672-.887-6.177-1.259-12.865-2.549-18.507 1.106-3.148 2.037-5.642 4.986-8.33 7.566-3.425 3.28-6.97 6.436-10.62 9.46-7.19 5.962-14.812 11.395-22.771 16.276-1.688 1.038-3.418 2.33-5.244 3.112-1.836.785-4.204.86-6.339 1.08-5.3.555-10.607 1.065-15.917 1.522-21.35 1.831-42.752 2.645-64.178 2.526-2.227-.01-2.23 3.448 0 3.461 17.41.096 34.815-.392 52.187-1.595 8.823-.613 17.636-1.392 26.434-2.3 2.905-.3 6.3-.133 8.97-1.372 3.817-1.773 7.47-4.407 10.983-6.71 6.542-4.292 12.834-8.97 18.842-13.984 3.121-2.604 6.162-5.304 9.116-8.098 3.034-2.866 5.923-6.639 9.663-8.58 6.16-3.196 13.61-.093 19.977.82 5.753.825 10.622.023 15.115-3.805 3.047-2.6 7.525-7.11 10.801-2.001 2.904 4.529-.422 9.546-3.56 12.874-6.854 7.265-14.485 13.914-21.71 20.807-.646.616-.693 1.842 0 2.447 3.062 2.692 6.443 4.435 10.45 5.238l-1.27-1.668c1.022 7.94-11.71 5.547-16.174 6.458-4.56.931-8.452 3.84-11.514 7.24-6.644 7.382-10.075 20.17-19.64 24.482-1.178.533-2.827.37-3.868 1.16-.871.66-1.369 1.815-2.133 2.603-2.16 2.23-5.2 3.356-8.172 4.002-6.015 1.31-12.45.968-18.568 1.068-13.756.219-27.515.211-41.27.268-15.789.068-31.577.231-47.353.878-2.219.093-2.23 3.554 0 3.461"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#FEFEFE"
          fillRule="nonzero"
          d="M474.278 62.998c5.238 4.231 5.468 12.009 6.51 18.723 1.897 12.202 7.827 23.39 10.525 35.435.622 2.773 1.064 5.712.224 8.424-.84 2.712-3.314 5.095-6.107 4.91-3.86-.255-5.856-4.691-7.03-8.436l-14.487-46.206c-.591-1.882-1.187-3.87-.778-5.804.815-3.844 5.043-5.69 8.716-6.937 1.69-.574 1.57-1.033 3.259-1.607"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M472.91 64.003c4.028 3.465 4.87 8.559 5.592 13.528.804 5.528 1.901 10.843 3.66 16.163 1.86 5.632 4.108 11.126 5.902 16.776 1.029 3.242 2.317 6.892 2.438 10.321.135 3.765-2.914 10.17-7.399 6.025-1.969-1.823-2.72-4.807-3.519-7.26-1.01-3.106-2.007-6.217-3.01-9.323l-6.023-18.645c-1.94-6.008-4.39-12.064-5.835-18.205-1.54-6.545 5.893-8.452 10.744-10.423 2.05-.835 1.153-4.169-.928-3.324-3.643 1.482-8.342 2.905-11.178 5.722-3.531 3.514-2.4 7.853-1.039 12.085 4.384 13.633 8.802 27.251 13.204 40.877 1.324 4.102 2.493 9.363 6.357 11.968 3.207 2.16 7.218 1.297 9.7-1.517 3.763-4.265 2.353-10.355 1.02-15.291-1.664-6.164-4.077-12.092-6.18-18.114-2.218-6.355-3.643-12.66-4.622-19.295-.8-5.407-2.078-10.776-6.413-14.504-1.688-1.452-4.17.976-2.472 2.436M520.567 51.098c-2.017.479-3.843 1.958-4.405 3.93-.562 1.972.37 4.345 2.27 5.17 3.033 1.32 6.484-1.925 6.566-5.196.033-1.303-.328-2.668-1.263-3.586-.933-.916-2.513-1.244-3.61-.524"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          d="M520.646 50.11c-3.26.922-6.001 3.72-5.608 7.276.367 3.33 3.45 5.846 6.823 4.92 3.234-.89 5.417-4.398 5.11-7.643-.336-3.546-3.614-6.423-7.137-4.598-1.804.933-.21 3.644 1.593 2.71 2.01-1.042 2.633 2.212 2.298 3.51-.354 1.377-1.622 2.983-3.163 3.075-1.548.094-2.556-1.486-2.411-2.913.177-1.749 1.762-2.865 3.335-3.31 1.953-.551 1.122-3.58-.84-3.026M490.632 71.397c.165-.239-.404.355-.172.18-.2.151-.291.182-.355.207-.083.03-.623.117-.38.103-.13.006-.26.004-.39-.004.158.018.126.004-.094-.042-2.25-.575-3.208 2.922-.96 3.498 2.134.544 4.215-.325 5.462-2.111.56-.803.143-2.017-.646-2.483-.9-.528-1.905-.154-2.465.652"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#F293BD"
          fillRule="nonzero"
          d="M446.763 86.597c4.647 7.387 9.293 14.778 13.938 22.17 2.452 3.9 4.94 7.843 8.245 11.038 1.634 1.581 3.648 3.598 2.889 5.754-.256.732-.809 1.311-1.345 1.866l-7.07 7.33c-1.002 1.034-2.061 2.112-3.43 2.539-3.027.949-5.959-1.551-8.105-3.905-9.196-10.085-17.17-21.308-23.685-33.335-.115-.211-.231-.444-.192-.682.04-.238.23-.421.408-.58 5.142-4.636 10.736-8.76 16.675-12.292"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#1C1C1A"
          fillRule="nonzero"
          d="M445.821 88.216c5.729 9.068 11.14 18.46 17.328 27.217 1.042 1.475 2.137 2.928 3.34 4.275 2.144 2.401 5.2 3.892 2.757 7.432-1.432 2.074-3.777 3.898-5.54 5.709-.769.788-1.5 1.743-2.44 2.334-2.369 1.49-4.531.292-6.346-1.351-7.22-6.536-13.16-15.351-18.404-23.502-1.227-1.9-2.412-3.829-3.56-5.782-.43-.733-2.033-2.624-2.02-3.537.02-1.48 1.416-2.213 2.422-3.045 4.135-3.427 8.551-6.464 13.149-9.223 1.9-1.14.171-4.13-1.736-2.987-5.588 3.358-11.135 7.115-15.945 11.549-1.534 1.414-2.297 2.204-1.516 4.26 1.066 2.811 3.201 5.668 4.783 8.215 3.42 5.51 7.166 10.814 11.187 15.898 3.449 4.365 7.705 11.018 13.029 13.247 5.225 2.186 8.566-2.33 11.845-5.71 3.472-3.576 8.293-7.009 4.399-12.104-1.738-2.273-3.975-4.095-5.702-6.408-2.103-2.82-3.917-5.859-5.794-8.827L448.793 86.47c-1.183-1.872-4.164-.14-2.972 1.745"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#174BA0"
          fillRule="nonzero"
          d="M468.197 130.8c3.278-2.52 8.828-1.177 10.859 2.311 2.03 3.49.63 8.222-2.71 10.67-2.866 2.099-7.413 2.464-9.97.037-1.06-1.006-1.674-2.357-2.033-3.732-.522-1.995-.529-4.207.552-5.993 1.08-1.79 1.866-4.431 3.949-3.805"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#1C1C1A"
          fillRule="nonzero"
          d="M468.597 132.045c2.381-1.714 5.828-1.338 7.723 1.004 2.126 2.628 1.215 6.693-1.006 8.948-2.372 2.41-7.002 3.093-8.918-.231-.915-1.586-1.32-3.878-.885-5.665.111-.454 1.707-4.513 2.376-4.41 2.196.336 3.14-3.011.927-3.35-5.742-.877-7.564 8.2-6.546 12.296 1.482 5.973 7.537 8.563 13.006 5.687 4.905-2.578 7.327-9.318 4.565-14.257-2.492-4.459-8.83-6.024-13.003-3.02-1.8 1.297-.061 4.31 1.76 2.998M445.153 103.5h-.305c-2.462 0-2.466 3 0 3h.305c2.461 0 2.464-3 0-3h-.305c-2.462 0-2.466 3 0 3h.305c2.461 0 2.464-3 0-3M444.5 98.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M436.5 102.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M442.772 116.5h.456c.926 0 1.813-.689 1.77-1.5-.042-.811-.777-1.5-1.77-1.5h-.456c-.926 0-1.813.691-1.77 1.5.044.814.777 1.5 1.77 1.5M452.5 109.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M449.5 121.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M456.5 120.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M457.5 130.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3M464.5 127.5c1.999 0 2.001-3 0-3-1.999 0-2.001 3 0 3"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          stroke="#000"
          strokeWidth=".5"
          d="M244.575 149.742c10.397.902 21.433 1.554 31.57-1.42 17.975-5.274 28.437-25.279 23.729-43.275-2.512-9.603-12.983-16.345-21.939-10.175-1.48 1.021-.074 3.454 1.422 2.422 5.841-4.024 12.937-1.453 16.298 4.606 2.538 4.581 2.643 10.377 2.117 15.463-1.067 10.287-6.244 20.244-15.505 25.41-9.364 5.226-20.71 5.186-31.129 4.614-11.454-.628-22.245-1.782-30.725-10.12-1.292-1.27-3.287.711-1.994 1.984 7.31 7.186 16.054 9.615 26.156 10.491z"
          transform="translate(0 -6) translate(0 14)"
        />
        <g transform="translate(0 -6) translate(0 14) translate(164 49.5)">
          <mask id="prefix__b" fill="#fff">
            <use xlinkHref="#prefix__a" />
          </mask>
          <path
            fill="#010202"
            fillRule="nonzero"
            stroke="#000"
            strokeWidth=".5"
            d="M151.688 9.43c20.153 12.032 26.565 36.943 24.758 58.874-.978 11.862-3.396 24.977-8.82 35.704-5.834 11.538-16.943 16.473-28.583 20.722-23.355 8.528-48.555 11.901-73.342 9.742-24.224-2.108-53.53-11.818-62.177-37.118-.582-1.7-3.304-.97-2.72.746 7.62 22.29 31.378 33.978 53.263 37.706 26.64 4.54 54.775 1.877 80.383-6.52 11.08-3.635 23.97-7.878 31.454-17.323 7.716-9.742 10.507-23.38 12.363-35.343 3.577-23.09-.13-49.479-19.139-65.326-22.207-18.513-53.56-11.963-75.435 3.36-1.473 1.03-.066 3.461 1.422 2.421 19.08-13.364 45.254-20.379 66.573-7.645z"
            mask="url(#prefix__b)"
          />
        </g>
        <path
          fill="#010202"
          fillRule="nonzero"
          stroke="#000"
          strokeWidth=".5"
          d="M228.404 101.14c4.35 5.983 9.497 11.109 14.822 16.236 5.974 5.755 12.128 11.33 18.46 16.696 5.733 4.862 10.177 9.053 17.174 11.978 1.676.7 3.862-1.68 2.206-2.372-8.748-3.66-15.904-10.2-22.918-16.383-6.957-6.13-13.713-12.497-20.209-19.108-5.182-5.273-8.97-11.544-13.596-17.266-2.188-2.705-6.622-8.35-10.687-5.756-1.527.972-.11 3.404 1.428 2.425 3.509-2.239 11.924 11.632 13.32 13.55z"
          transform="translate(0 -6) translate(0 14)"
        />
        <path
          fill="#010202"
          fillRule="nonzero"
          stroke="#000"
          strokeWidth=".5"
          d="M279.405 96.202c-4.326-4.57-7.944-9.815-12.137-14.517-4.412-4.947-9.09-9.658-14-14.106-4.545-4.12-9.303-8.574-14.82-11.365-5.468-2.766-11.65-3.444-17.65-4.196-6.423-.805-12.501-.834-18.735 1.091-11.11 3.43-21.726 8.899-30.418 16.673-8.334 7.452-13.186 15.11-12.645 26.42.087 1.808 2.944 2.716 2.857.9-1.109-23.155 23.172-36.644 42.89-41.828 6.42-1.686 12.866-.902 19.339.001 7.33 1.022 13.506 2.995 19.403 7.631 9.48 7.453 18.099 16.197 25.819 25.444 3.802 4.556 7.156 9.77 11.997 13.314 4.77 3.492 10.536 4.646 16.336 4.835 1.813.06 1.81-2.759 0-2.819-7.081-.232-13.28-2.238-18.236-7.478z"
          transform="translate(0 -6) translate(0 14)"
        />
      </g>
      <g fillRule="nonzero">
        <path
          fill="#FF94CA"
          d="M21.006 5.824c1.015-.971 2.094-1.877 3.11-2.783l3.68 3.947c-2.221-.388-4.506-.776-6.79-1.164zm4.696 8.929c-4.189-.453-8.314-1.035-12.502-1.747-1.777 1.682-3.554 3.43-5.33 5.176 3.934.518 12.438 1.812 12.438 1.812s3.744-3.559 5.394-5.241zM2.856 23.229c.507.583 1.079 1.165 1.586 1.747h.19c3.047.389 9.647.712 9.647.712s2.856-2.653 4.442-4.206C14.723 20.9 6.537 19.8 6.41 19.671 5.204 20.77 3.998 22 2.856 23.229zM19.483 7.247c-1.523 1.359-3.046 2.782-4.57 4.206 3.745.582 12.375 1.682 12.375 1.682L30.462 9.9l-.635-.647C26.59 8.67 19.61 7.376 19.483 7.247zM6.156 26.982c1.015 1.165 2.03 2.33 3.046 3.43.38-.065 1.713-1.36 3.554-3.106-2.221-.065-4.38-.065-6.6-.324z"
          transform="translate(0 -6) rotate(33 194.782 1021.272)"
        />
        <path
          fill="#000"
          d="M23.29 2.07C22.783 1.36 4.315 19.219 1.206 22.26c-.254.13-.508.388-.571.647v.13c-.064.452.19.84.507.97 2.348 2.588 7.235 8.153 7.425 8.282.318.388.762.324 1.016.065.127 0 15.167-14.43 22.529-21.547.317-.324.317-.647.126-.97.127-.324.064-.648-.19-.971-2.475-2.653-4.95-5.37-7.425-8.024-.698-.712-1.904.388-1.333 1.23zm-4.569 19.348c-1.586 1.553-4.442 4.206-4.442 4.206s-6.6-.26-9.646-.712h-.19c-.508-.583-1.08-1.165-1.587-1.747 1.206-1.23 2.411-2.394 3.554-3.624.127.194 8.313 1.359 12.311 1.877zM7.87 18.182c1.777-1.747 3.554-3.43 5.331-5.176 4.125.712 8.313 1.294 12.502 1.747-1.65 1.682-5.394 5.241-5.394 5.241S11.804 18.7 7.869 18.182zm-1.713 8.8c2.22.26 4.379.26 6.6.389-1.84 1.747-3.173 3.04-3.554 3.105-1.015-1.229-2.03-2.329-3.046-3.494zm21.132-13.847s-8.63-1.1-12.375-1.682c1.524-1.424 3.047-2.847 4.57-4.206.127.13 7.107 1.424 10.407 2.006l.635.647c-.698.647-3.237 3.235-3.237 3.235zm.572-6.147l-6.854-1.164c1.015-.971 2.094-1.877 3.11-2.783l3.744 3.947z"
          transform="translate(0 -6) rotate(33 194.782 1021.272)"
        />
      </g>
      <g fillRule="nonzero">
        <path
          fill="#74B3E1"
          d="M20.795 5.762c1.005-.96 2.073-1.856 3.078-2.753l3.644 3.906c-2.199-.384-4.46-.769-6.722-1.153zm4.649 8.836c-4.146-.449-8.23-1.025-12.376-1.729-1.76 1.665-3.519 3.393-5.278 5.122 3.895.512 12.314 1.793 12.314 1.793s3.706-3.522 5.34-5.186zM2.827 22.985c.503.576 1.068 1.152 1.57 1.729h.19c3.015.384 9.549.704 9.549.704s2.827-2.625 4.397-4.162c-3.958-.576-12.062-1.664-12.188-1.792-1.193 1.088-2.387 2.304-3.518 3.52zM19.287 7.17c-1.508 1.344-3.015 2.753-4.523 4.161 3.706.577 12.25 1.665 12.25 1.665l3.142-3.201-.628-.64c-3.205-.577-10.115-1.857-10.24-1.985zM6.094 26.698c1.005 1.153 2.01 2.305 3.016 3.394.377-.064 1.696-1.345 3.518-3.074-2.2-.064-4.335-.064-6.534-.32z"
          transform="translate(0 -6) rotate(139 274.075 116.783)"
        />
        <path
          fill="#000"
          d="M23.057 2.049c-.503-.704-18.785 16.966-21.863 19.976-.252.128-.503.384-.566.64v.128c-.063.448.189.832.503.96 2.324 2.561 7.162 8.067 7.35 8.195.314.385.754.32 1.006.064.125 0 15.015-14.277 22.302-21.32.314-.32.314-.64.126-.96.126-.32.063-.64-.189-.96-2.45-2.626-4.9-5.315-7.35-7.94-.691-.704-1.885.384-1.32 1.217zm-4.524 19.143c-1.57 1.537-4.397 4.162-4.397 4.162s-6.534-.256-9.55-.704h-.188c-.503-.577-1.068-1.153-1.57-1.73 1.193-1.216 2.386-2.368 3.517-3.585.126.193 8.23 1.345 12.188 1.857zM7.79 17.991c1.76-1.729 3.518-3.393 5.278-5.122 4.083.704 8.23 1.28 12.376 1.729-1.634 1.664-5.34 5.186-5.34 5.186s-8.419-1.28-12.314-1.793zm-1.696 8.707c2.199.256 4.335.256 6.534.384-1.822 1.73-3.141 3.01-3.518 3.074-1.006-1.217-2.01-2.305-3.016-3.458zm20.92-13.701s-8.544-1.088-12.25-1.665c1.508-1.408 3.015-2.817 4.523-4.161.126.128 7.036 1.408 10.303 1.985l.629.64c-.691.64-3.204 3.201-3.204 3.201zm.566-6.082l-6.785-1.153c1.005-.96 2.073-1.856 3.078-2.753l3.707 3.906z"
          transform="translate(0 -6) rotate(139 274.075 116.783)"
        />
      </g>
    </g>
  </svg>
);
