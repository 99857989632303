/* eslint-disable no-console */

import React, { useState, useEffect, useContext } from 'react';
import { PageTracking, trackEvent, AnalyticsContext } from 'latitude-analytics';
import {
  Button,
  Container,
  DatePicker,
  Form,
  Grid,
  Input,
  Message,
  Stack,
  Text
} from 'basis';
import styled, { css } from 'styled-components';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import {
  dateOfBirthValidation,
  emailValidation
} from '@/components/PersonalLoanSoftQuote/validator/aboutYouValidator';
import NeedHelpSection from '@/components/PersonalLoanSoftQuote/partials/NeedHelpSection';
import {
  ANALYTICS_SITESUBSECTION,
  PL_GEM_TEL,
  PL_TEL
} from '@/utils/constants';
import { getEnvForDebug } from '@/utils/getUrlFromEnvUtil';
import {
  REQUEST_CHANNEL,
  RETRIEVE_USER_STATUS,
  SALESFORCE_RETRIEVE_STATUS
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import { dataLayerOnQuoteRetrieveSendCode } from '@/utils/dataLayerUtil';
import {retrieveQuote} from '@/components/PersonalLoanSoftQuote/library/softQuoteProxyApi';
import tealiumConfig from '../../../../tealium';

const DEBUG = getEnvForDebug();
export const RetrieveQuoteSection = props => {
  let [analytics] = useContext(AnalyticsContext);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const isGem = props.isGem;
  analytics.siteData.page.siteCountry = isGem ? 'nz' : 'au';
  tealiumConfig.siteData.page.siteCountry = isGem ? 'nz' : 'au';

  function retrieveQuoteSubmit(errors, values) {
    if (!Object.keys(errors).length > 0) {
      dataLayerOnQuoteRetrieveSendCode(analytics, values);
      trackEvent(
        analytics,
        {
          category: 'quote',
          action: 'send-code',
          location: 'Quote-Retrieval',
          label: 'Send code'
        }
      );
      props.dispatch.setLoadingOverlayIsActive(true);

      const postData = {
        dateOfBirth:
          values.dateOfBirth.day.padStart(2, '0') +
          '/' +
          values.dateOfBirth.month.padStart(2, '0') +
          '/' +
          values.dateOfBirth.year,
        emailID: values.emailAddress
      };

      generateOtp(postData, props.userData, props.dispatch, setShowErrorMessage, isGem, analytics);
    }
  }

  // analytics - if this is a gem page, change the `siteCountry` to `nz`
  useEffect(() => {
    if (props.isGem) tealiumConfig.siteData.page.siteCountry = 'nz';
  }, []);

  return (
    <>
      <Title>Retrieve your details</Title>

      <Stack gap="4" margin="0 4" margin-md="0">
        {props.queryString.includes('duplicate-check') && (
          <PageTracking
            path={`/${ANALYTICS_SITESUBSECTION}/retrieve-duplicate${
              props.isGem ? '-gem' : ''
            }`}
            exceptions={tealiumConfig.pageDataExceptions}
          >
            <Message severity="info-or-minor" bg="secondary.lightBlue.t25">
              As you already have a quote, you can securely retrieve your
              details to review your interest rate and start an application.
            </Message>
          </PageTracking>
        )}

        <Text>
          Enter your details below and we&apos;ll send a unique verification
          code to your phone. This ensures the personal information you entered
          in your quote is kept <strong>safe and secure</strong>.
        </Text>

        {showErrorMessage}
      </Stack>

      <Container bg="grey.t07" margin="0 4" margin-md="0" padding="4">
        <Form
          initialValues={{
            quoteReferenceNumber: props?.userData?.quoteNumber || '',
            emailAddress: props?.userData?.emailId || '',
            dateOfBirth: {
              day: '',
              month: '',
              year: ''
            }
          }}
          onSubmit={({ errors, values }) => {
            retrieveQuoteSubmit(errors, values);
          }}
        >
          <Grid rowsGap={4}>
            <Input
              name="quoteReferenceNumber"
              label="Quote reference number"
              validate={(value, args, minLength = 7, maxLength = 14) => {
                if (value.length < minLength || value.length > maxLength) {
                  return `Quote reference number must be 8-15 characters starting with Q (eg. Q2020000000)`;
                } else {
                  return null;
                }
              }}
              optional
            />

            <Input
              name="emailAddress"
              label="Email address"
              validate={emailValidation}
            />
            <DatePicker
              name="dateOfBirth"
              label="Date of birth"
              validate={dateOfBirthValidation}
            />
            <WideButtonContainer>
              <Button type="submit" color="green" width="100%">
                Send code
              </Button>
            </WideButtonContainer>
          </Grid>
        </Form>
      </Container>
      <NeedHelpSection isGem={isGem} />
    </>
  );
};

/* generateOtp
 * Generate OTP code with salesforce API to be used in 2 factor authentication
 * args:
 *    postData - object - data to post to salesforce api
 *    userData - object - state values from parent component
 *    dispatch - function - updates state in parent component
 *    errorCallback - boolean - show error message
 */
export function generateOtp(
  postData,
  userData,
  dispatch,
  errorCallback,
  isGem,
  analytics
) {
  const phoneNumber = isGem ? PL_GEM_TEL : PL_TEL;
  const requestChannel = isGem ? REQUEST_CHANNEL.GFCoNz : REQUEST_CHANNEL.LFComAu;

  retrieveQuote(postData, requestChannel)
    .then(function(response) {
      dispatch.setLoadingOverlayIsActive(false);
      if (DEBUG) console.log(response);

      if (response.data.status == SALESFORCE_RETRIEVE_STATUS.SUCCESS) {
        // Success - OTP Generated Successfully.

        if (DEBUG) console.log(response.data.data.SUCCESS_MSG);

        dispatch.setUserData({
          ...userData,
          transactionId: response.data.data.SF_TRANSACTION_ID,
          quoteId: response.data.data.QUOTE_ID,
          phoneNumber: response.data.data.QUOTE_MOBILE_NUMBER,
          dateOfBirth: postData.dateOfBirth,
          emailId: postData.emailID,
          newCodeRemaining: response.data.data.ATTEMPTS_REMAINING,
          codeMatchAttemptsRemaining: 3, // TODO - use a default variable
          userStatus: RETRIEVE_USER_STATUS.verify,
          otpSent: true,
          expiredOtp: false
        });
      }

      if (response.data.status == SALESFORCE_RETRIEVE_STATUS.ERROR) {
        // Error - EMAIL_ID_BLANK - Email Id is mandatory and cannot be blank.
        if (SALESFORCE_RETRIEVE_STATUS.EMAIL_ID_BLANK in response.data.error) {
          errorCallback(DefaultError);
          if (DEBUG) console.log(response.data.error.EMAIL_ID_BLANK);
        }

        // Error - INVALID_DOB_FORMAT - Date of Birth is mandatory and cannot be blank.
        // Error - INVALID_DOB_FORMAT - Date of Birth should be in DD/MM/YYYY format.
        if (
          SALESFORCE_RETRIEVE_STATUS.INVALID_DOB_FORMAT in response.data.error
        ) {
          errorCallback(DefaultError);
          if (DEBUG) console.log(response.data.error.INVALID_DOB_FORMAT);
        }

        // Error - NO_ACTIVE_QUOTE - No active quote found.
        if (SALESFORCE_RETRIEVE_STATUS.NO_ACTIVE_QUOTE in response.data.error) {
          if (DEBUG) console.log(response.data.error.NO_ACTIVE_QUOTE);

          // Quote is Converted to Application
          if (
            response.data.data.QUOTE_STAGE ===
            SALESFORCE_RETRIEVE_STATUS.ConvertedToApplication
          ) {
            if (DEBUG) console.log(response.data.data.QUOTE_STAGE);
            dispatch.setUserData({
              ...userData,
              userStatus: SALESFORCE_RETRIEVE_STATUS.ConvertedToApplication
            });
          } else {
            errorCallback(DefaultError);
          }
        }

        // Error - MAX_SMS_ATTEMPTS_REACHED - You have exhausted your maximum attempts to generate an OTP.
        if (
          SALESFORCE_RETRIEVE_STATUS.MAX_SMS_ATTEMPTS_REACHED in
          response.data.error
        ) {
          dispatch.setUserData({
            ...userData,
            codeMatchAttemptsRemaining: 0,
            newCodeRemaining: 0
          });
          errorCallback(
            <Message severity="blocking" bg="secondary.pink.t30">
              You&apos;ve reached the maximum number of attempts to enter your
              SMS code. Please call us on{' '}
              <a
                href={`tel:${phoneNumber}`}
                onClick={() => {
                  trackEvent(analytics, {
                    category: 'quote',
                    action: 'phone-link',
                    location: 'Quote-Retrieval-max-attempts',
                    label: phoneNumber
                  });
                }}
              >
                {phoneNumber}
              </a>{' '}
              to retrieve your rate
            </Message>
          );
          if (DEBUG) console.log(response.data.error.MAX_SMS_ATTEMPTS_REACHED);
        }
      }
    })
    .catch(function(error) {
      dispatch.setLoadingOverlayIsActive(false);
      dispatch.setUserData({
        ...userData,
        userStatus: RETRIEVE_USER_STATUS.server_error
      });
      if (DEBUG) console.log(error);
    });

  const DefaultError = (
    <Message severity="warning-or-significant" bg="secondary.pink.t30">
      The details you&apos;ve entered do not match a quote in our system. Please
      try again.
    </Message>
  );
}

export const WideButtonContainer = styled.div`
  ${props =>
    props.buttonIsDisabled &&
    css`
      button {
        pointer-events: none;
      }
    `};

  @media (min-width: ${BREAKPOINT.LG}) {
    min-width: 250px;
    margin: 0 auto;
  }
`;

export const Title = props => (
  <Text textStyle="heading3" align="center" margin="4 4">
    {props.children}
  </Text>
);
