// @flow

import * as React from 'react';
import Helmet from 'react-helmet';
import { SITE_URL } from './Metadata.constants';

type MetadataProps = {
  /** Set a page title (required). */
  title: string,
  /** Set a meta description. */
  description?: string,
  /** Set meta keywords. */
  keywords?: string,
  /** Set a canonical URL for similar or duplicate pages. */
  canonical?: string,
  /** If true, prepends the relative path with SITE_URL. */
  isCanonicalURLRelative?: boolean,
  /** If true, adds a noindex meta tag to prevent SEO robot adds the page to search index */
  noIndex?: boolean,
  /** If true, adds a nofollow meta tag to prevent SEO robot crawls the links on the page */
  noFollow?: boolean,
  /** Any additional tags to pass through to <Helmet>. */
  children?: React.Node
};

const Metadata = ({
  isCanonicalURLRelative = true,
  canonical,
  description,
  keywords,
  title,
  noIndex = false,
  noFollow = false,
  children
}: MetadataProps) => {
  // work out meta robots value
  let metaRobotsValue = null;
  if (noIndex && !noFollow) {
    metaRobotsValue = 'noindex';
  } else if (!noIndex && noFollow) {
    metaRobotsValue = 'nofollow';
  } else if (noIndex && noFollow) {
    metaRobotsValue = 'noindex, nofollow';
  }

  let metaSiteVerificationValue = 'fp21jzzlOiYScJolrSp4CEJ7-q5FLalJ1AKBnG8I7H8';

  return (
    <Helmet>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      {metaRobotsValue && <meta name="robots" content={metaRobotsValue} />}
      <meta
        name="google-site-verification"
        content={metaSiteVerificationValue}
      />
      {canonical && (
        <link
          rel="canonical"
          href={getCanonicalURL(canonical, isCanonicalURLRelative)}
        />
      )}
      {children}
    </Helmet>
  );
};

const getCanonicalURL = (
  canonical: string,
  isCanonicalURLRelative: boolean
): string => {
  return canonical
    ? `${isCanonicalURLRelative ? SITE_URL : ''}${canonical}`
    : '';
};

export default Metadata;
