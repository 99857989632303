import React from 'react';
import { Header } from 'basis';


function SoftQuoteHeader({product}) {
  let logo;
  if (product === 'gem') {
    logo = 'gem';
  } else {
    logo = 'latitude';
  }

  return (
    <Header>
      <Header.Logo name={logo} />
    </Header>
  );
}

SoftQuoteHeader.ID = 'SoftQuoteHeader';
SoftQuoteHeader.HEIGHT_MAP = Header.HEIGHT_MAP;

export { SoftQuoteHeader };
