/* eslint-disable no-console */

// rules and messaging: https://di.latitudefinancial.com/wiki/pages/viewpage.action?spaceKey=LFS&title=2FA+SMS+for+Personal+Loans
// salesforce integration guidelines: https://di.latitudefinancial.com/wiki/pages/viewpage.action?spaceKey=LFS&title=Salesforce%3A+Two+Factor+Authentication+%282FA%29+API+Information

import React, { useState, useEffect, useContext } from 'react';
import {
  AnalyticsContext,
  trackEvent,
  PageTracking
} from 'latitude-analytics';
import styled from 'styled-components';
import {
  Sticky,
  Container,
  Grid,
  Text,
  Button,
  LoadingIcon,
  Flex
} from 'basis';
import { BREAKPOINT } from '@latitude/core/utils/constants';
import SvgInline from '@latitude/svg-inline';
import {ANALYTICS_SITESUBSECTION, SOFT_QUOTE_AU_URL, SOFT_QUOTE_NZ_URL} from '@/utils/constants';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import { SimplifiedFooter } from '@/components/PersonalLoanSoftQuote/SimplifiedFooter';
import { ServerErrorContent } from '@/components/PersonalLoanSoftQuote/ServerError';
import NeedHelpSection from '@/components/PersonalLoanSoftQuote/partials/NeedHelpSection';
import { LoadingIconText } from '@/components/Loading/LoadingIconText/LoadingIconText';
import { getPurposeOptions, getRepaymentPeriodObject } from '@/components/EstimateRateWidget/MainRateWidget';
import {getEnvForDebug, getUrlFromEnv} from '@/utils/getUrlFromEnvUtil';
import {
  RETRIEVE_USER_STATUS,
  SALESFORCE_RETRIEVE_STATUS
} from '@/components/PersonalLoanSoftQuote/constants/softQuoteConstants';
import {
  RetrieveQuoteSection,
  Title
} from '@/components/PersonalLoanSoftQuote/retrieve/RetrieveQuoteSection';
import { VerifySmsSection } from '@/components/PersonalLoanSoftQuote/retrieve/VerifySmsSection';
import { dataLayerOnQuoteRetrieved, dataLayerOnQuoteRetrieveStart } from '@/utils/dataLayerUtil';
import {TimeoutModalHandler} from '@/components/TimeoutModal/TimeoutModal';
import { SoftQuoteHeader } from '../Header';
import { SoftQuoteLayout, productGem } from '../SoftQuoteLayout';
import ApprovedMessage from '../ApprovedMessage';
import tealiumConfig from '../../../../tealium';

export function RetrieveQuote(props) {
  let [analytics] = useContext(AnalyticsContext);
  analytics.siteData.page.siteCountry = props.isGem ? 'nz' : 'au';
  tealiumConfig.siteData.page.siteCountry = props.isGem ? 'nz' : 'au';
  dataLayerOnQuoteRetrieveStart(analytics);

  // userStatus - one of: retrieve, verify, expired, server-error, proceed, approved
  const [userData, setUserData] = useState({ userStatus: false });

  // toggles overlay with loading icon
  const [loadingOverlayIsActive, setLoadingOverlayIsActive] = useState();

  // allows child components to modify parent state
  const dispatch = {
    setUserData: setUserData,
    setLoadingOverlayIsActive: setLoadingOverlayIsActive
  };

  const urlParams = new URLSearchParams(props.location.search);
  const qId = urlParams.get('qId');

  // default user status
  if (!qId && !userData.userStatus) {
    setUserData({
      ...userData,
      userStatus: 'retrieve'
    });
  }

  // if qId is set in the querystring, get quote data from salesforce api
  if (qId && !userData.userStatus) {
    getQuoteData(userData, dispatch, urlParams.get('qId'), props.location.search, props.isGem);
  }

  const commonProps = {
    userData: userData,
    dispatch: dispatch,
    queryString: props.location.search
  };

  // analytics - if this is a gem page, change the `siteCountry` to `nz`
  useEffect(() => {
    if (props.isGem) tealiumConfig.siteData.page.siteCountry = 'nz';
  }, []);

  return (
      <Layout location={props.location} noHeader noFooter>
        <Metadata {...PageData.metaData} />
        <SoftQuoteLayout>
          <TimeoutModalHandler isGem={props.isGem} />
          <Sticky>
            <Sticky.Item>
              <SoftQuoteHeader product={props.isGem && productGem} />
            </Sticky.Item>
            <SectionLayout
              fullWidth={userData.userStatus == RETRIEVE_USER_STATUS.approved}
            >
              <LoadingOverlay
                isActive={!userData.userStatus || loadingOverlayIsActive}
              />
              {!userData.userStatus && <FullHeightContainer />}
              {userData.userStatus == RETRIEVE_USER_STATUS.retrieve && (
                  <RetrieveQuoteSection
                    {...commonProps}
                    isGem={props.isGem}
                    analytics = {analytics}
                  />
              )}

              {userData.userStatus == RETRIEVE_USER_STATUS.verify && (
                <PageTracking
                  path={`/${ANALYTICS_SITESUBSECTION}/retrieve-verify${props.isGem ? '-gem' : ''}`}
                  exceptions={tealiumConfig.pageDataExceptions}
                >
                  <VerifySmsSection
                    {...commonProps}
                    isGem={props.isGem}
                  />
                </PageTracking>
              )}

              {userData.userStatus == RETRIEVE_USER_STATUS.expired && (
                <PageTracking
                  path={`/${ANALYTICS_SITESUBSECTION}/retrieve-expired${props.isGem ? '-gem' : ''}`}
                  exceptions={tealiumConfig.pageDataExceptions}
                >
                  <QuoteExpiredSection
                    userData={userData}
                    isGem={props.isGem}
                  />
                </PageTracking>
              )}

              {userData.userStatus == RETRIEVE_USER_STATUS.server_error && (
                <PageTracking
                  path={`${typeof window !== 'undefined' &&
                    location.pathname}/server-error`}
                  exceptions={tealiumConfig.pageDataExceptions}
                >
                  <ServerErrorContent hideTryAgain isGem={props.isGem} />
                </PageTracking>
              )}

              {userData.userStatus == RETRIEVE_USER_STATUS.proceed && (
                <ProceedSection
                  {...commonProps}
                  {...userData}
                  isGem={props.isGem}
                />
              )}

              {userData.userStatus ===
                SALESFORCE_RETRIEVE_STATUS.ConvertedToApplication &&
                dataLayerOnQuoteRetrieved(analytics, userData) && (
                <PageTracking
                  path={`/${ANALYTICS_SITESUBSECTION}/retrieve-converted-to-app${props.isGem ? '-gem' : ''}`}
                  exceptions={tealiumConfig.pageDataExceptions}
                >
                  <ApplicationInProgressSection isGem={props.isGem}/>
                </PageTracking>
              )}

              {userData.userStatus == RETRIEVE_USER_STATUS.approved &&
                dataLayerOnQuoteRetrieved(analytics, userData) && (
                <PageTracking
                path={`/${ANALYTICS_SITESUBSECTION}/retrieve-success${props.isGem ? '-gem' : ''}`}
                  exceptions={tealiumConfig.pageDataExceptions}
                >
                  <ApprovedContainer>
                    <ApprovedMessage
                      {...commonProps}
                      loanAmount={userData.loanAmount}
                      loanTerm={userData.loanTerm}
                      loanPurpose={userData.loanPurpose}
                      interestRate={userData.interestRate}
                      securedInterestRate={userData.securedInterestRate}
                      quoteNumber={userData.quoteNumber}
                      encryptedQuoteID={userData.encryptedQuoteID}
                      name={userData.name}
                      isGem={props.isGem}
                      isBetterStart={userData.isBetterStart}
                    />
                  </ApprovedContainer>
                </PageTracking>
              )}
            </SectionLayout>
            <SimplifiedFooter product={props.isGem && productGem} />
            {/* Can be re-enabled if needed for testing */}
          </Sticky>
        </SoftQuoteLayout>
      </Layout>
  );
}
// variables ///////////////////////////////////////////////////////////////////
const DEBUG = getEnvForDebug();
const PageData = {
  metaData: {
    title: 'Retrieve your quote',
    description: '',
    keywords: '',
    canonical: ''
  }
};

/* QuoteExpiredSection
 *
 */
export const QuoteExpiredSection = props => {
  let [analytics] = useContext(AnalyticsContext);
  if (props.isGem) analytics.siteData.page.siteCountry = 'nz';

  const isGem = props.isGem;
  return (
    <>
      <Title>Sorry, your rate has expired</Title>
      <Text align="center">
        Please start a new quote or contact us if you have any questions.
      </Text>
      <Button
        color="green"
        margin="0 auto"
        onClick={() => {
          trackEvent(analytics, {
            category: 'quote',
            action: 'quote-link',
            location: 'Quote expired',
            label: 'Start new quote'
          });

          if (typeof window !== 'undefined') {
            window.location = props.isGem ? SOFT_QUOTE_NZ_URL : SOFT_QUOTE_AU_URL;
          }
        }}
      >
        Start new quote
      </Button>
      <NeedHelpSection isGem={isGem} />
      <SvgInline name="chat" css="width:140px; margin: 0 auto;" />
    </>
  );
};

/* ProceedSection
 *
 */
const ProceedSection = props => {
  useEffect(() => {
    if (
      // if user arrives by clicking 'apply now' in quote email they will be redirected to Apply intro page and skipping the Quote result page
      props.queryString?.includes('email-apply')
    ) {
      const applyUrl = props.isGem ? getUrlFromEnv(
        'pl-apply-url-gem'
        ) : getUrlFromEnv(
        'pl-apply-url-au'
      )
      window.location = `${applyUrl}?qId=${encodeURIComponent(props.userData.encryptedQuoteID)}`;
    }
    const redirectTimer = setTimeout(() => {
      if (DEBUG) console.log('ProceedSection props:',props);
      getQuoteData(props.userData, props.dispatch, props.userData.quoteId, props.queryString, props.isGem);
    }, 2500);

    return function cleanup() {
      clearTimeout(redirectTimer);
    };
  }, []);

  return (
    <FullHeightContainer>
      <Flex height="100%" placeItems="center">
        <LoadingIconText message="We're retrieving your details" />
      </Flex>
    </FullHeightContainer>
  );
};

export const ApplicationInProgressSection = props => {
  const [analytics] = useContext(AnalyticsContext);
  if (props.isGem) analytics.siteData.page.siteCountry = 'nz';

  const isGem = props.isGem;
  let phoneNumber = isGem ? '0800 422 898' : '1300 973 422';
  return (
    <>
      <Title>You already have an application in progress</Title>

      <Text align="center" margin="0 4">
        We&apos;ll be in touch shortly but if you have any questions in the
        meantime, please call us on{' '}
        <a
          href={`tel:${phoneNumber}`}
          onClick={() => {
            trackEvent(analytics, {
              category: 'quote',
              action: 'phone-link',
              location: 'Application in progress',
              label: phoneNumber
            });
          }}
        >
          {phoneNumber}
        </a>
        <br />
        {isGem ? (
          <>
            Mon to Fri: 8.30am - 6.30pm (NZST) <br /> Weekends: Closed
          </>
        ) : (
          <>
            Mon to Fri: 8am - 7pm (AEST) <br /> Weekends: Closed
          </>
        )}
      </Text>

      {/* TODO - replace placeholder with correct svg icon/ svg inline component
        <SvgInline name="chat" css="width:140px; margin: 0 auto;" />
      */}
      <img
        src={require('@/images/thumbs-up.svg')}
        alt="email"
        css="max-width:90px; margin: 0 auto;"
      />
    </>
  );
};

// functions ///////////////////////////////////////////////////////////////////


/* getQuoteData
 * Get quote data
 * args:
 *    userData - object - state values from parent component
 *    dispatch - function - updates state in parent component
 *    quoteRef - string - data to post to salesforce api
 */
function getQuoteData(
  userData,
  dispatch,
  quoteRef,
  querystring,
  isGem
) {
  success(userData);
  function success(data) {
    if (
      data.loanAmount &&
      data.loanTerm &&
      // data.quoteStatus && //TODO: To be removed
      data.quoteNumber &&
      data.encryptedQuoteID &&
      data.firstName
    ) {
      /**
       * The data.loanTerm is passed to SQ from sales force as a string. Before better start, the string was "1 year" or "2 years" up to "7 years".
       * After BetterStart, the string is more complicated, such as "1 year(+3 months)" or "2 Years(+3 months)".
       * Note that the value returned by the sales force API is NOT exactly the same as the text that is to be displayed in the UI.
       * There is no space between the "1 Year" and "(+3 Months)", but there needs to be a space in the UI version.
       * For other non-better start loanTerm values, the text to be displayed in the front end IS the same as the text value returned by the API.
       * Before betterstart, we could just take the first character of this string, which was always a digit, and that was the duration of the loan in years.
       * Or we could look up the value by looking at the text property of the repayment period options object, and also use this text property's value to display in the UI
       * After better start, we added the strings returned from the sales force API as a 'sfApiValue' property of the repyament period options object. 
       * If there is no sfApiValue property for a member of the repayment period object, that means the value returned by the API is the same as the `text`
       * We use a function to look up the correct value from the loanTerm returned by the API to allow this complexity to be shared in future:
       */
      const repaymentPeriodObject = getRepaymentPeriodObject( {isBetterStart: data.isBetterStart, isGem, sfApiValue: data.loanTerm} );
      repaymentPeriodObject && sessionStorage.setItem('repaymentPeriod',repaymentPeriodObject.value);
      const purposeOptions = getPurposeOptions({ isBetterStart: data.isBetterStart, isGem });
      const matchingPurposeObject = purposeOptions.find( onePurposeOption => onePurposeOption.text === data.loanPurpose);
      matchingPurposeObject && sessionStorage.setItem('purpose', matchingPurposeObject.value);
      sessionStorage.setItem('loanAmount', data.loanAmount);
      dispatch.setUserData({
        ...userData,
        loanTerm: repaymentPeriodObject?repaymentPeriodObject.text:'', // This over-writes the value returned by the API in QUOTE_LOAN_TERM with the formatted text to be written to the UI.
        emailId: data.emailId,
        interestRate: data.interestRate,
        securedInterestRate: data.securedInterestRate,
        quoteNumber: data.quoteNumber,
        encryptedQuoteID: data.encryptedQuoteID,
        name: data.firstName,
        userStatus: userData.userStatus == RETRIEVE_USER_STATUS.proceed ? RETRIEVE_USER_STATUS.approved : RETRIEVE_USER_STATUS.retrieve,
        isBetterStart: data.isBetterStart,
      });
    } else {
      error();
      if (DEBUG) console.log('Invalid qId');
    }
  }

  function error() {
    dispatch.setUserData({
      ...userData,
      userStatus: 'server-error'
    });
  }
}

// components //////////////////////////////////////////////////////////////////
export const SectionLayout = props => (
  <Container
    bg="grey.t03"
    padding="0"
    padding-lg="1"
    margin="0 0 0 0"
    margin-md="0 0 0 0"
  >
    <Container
      padding="0"
      margin="0"
      margin-md="0"
      margin-lg="8 auto 7"
      hasBreakpointWidth={false}
      hasBreakpointWidth-lg
    >
      <Container
        bg="white"
        padding="6 0"
        padding-xs="6 0"
        padding-md="6 0"
        padding-lg="6 0"
        hasBorder
        margin="0"
      >
        {props.fullWidth ? (
          <Grid rowsGap={6}>{props.children}</Grid>
        ) : (
          <div css="max-width:535px; margin: 0 auto;">
            <Grid rowsGap={6}>{props.children}</Grid>
          </div>
        )}
      </Container>
    </Container>
  </Container>
);

const LoadingOverlay = props => {
  const Outer = styled.div`
    display: ${props.isActive ? 'block' : 'none'};
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
  `;
  return (
    <Outer>
      <Flex height="100%" placeItems="center">
        <LoadingIcon size="medium" />
      </Flex>
    </Outer>
  );
};

const ApprovedContainer = styled.div`
  padding: 30px 20px;
  @media (min-width: ${BREAKPOINT.LG}) {
    padding: 32px 0;
  }
`;

const FullHeightContainer = styled.div`
  /* viewport - header - gutters - footer */
  height: calc(100vh - 56px - 52px - 206px);
  @media (min-width: ${BREAKPOINT.SM}) {
    height: calc(100vh - 56px - 52px - 160px);
  }
  @media (min-width: ${BREAKPOINT.MD}) {
    height: calc(100vh - 56px - 52px - 140px);
  }
  @media (min-width: ${BREAKPOINT.LG}) {
    height: calc(100vh - 80px - 120px - 140px);
  }
`;

// debug ///////////////////////////////////////////////////////////////////////
/* eslint-disable-next-line no-unused-vars */
const DebugPanel = props => {
  const NavButton = childProps => (
    <button
      type="button"
      onClick={() => {
        props.dispatch.setUserData({
          ...props.userData,
          userStatus: childProps.status
        });
      }}
    >
      {childProps.children}
    </button>
  );

  return (
    <>
      <Container hasBreakpointWidth padding="4">
        <div>
          <NavButton status="retrieve">Retrieve</NavButton> /
          <NavButton status="verify">Verify </NavButton> /
          <NavButton status="expired">Expired</NavButton> /
          <NavButton status="proceed">Proceed</NavButton> /
          <NavButton status="approved">Approved</NavButton>
        </div>
        <hr />
        Current session userData:
        <pre>
          <code>{JSON.stringify(props.userData, null, 2)}</code>
        </pre>
      </Container>
    </>
  );
};
