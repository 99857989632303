import React from 'react';
import { BasisProvider, defaultTheme } from 'basis';
import 'typeface-montserrat';
import 'typeface-roboto';

function SoftQuoteLayout({ children }) {
  return <BasisProvider theme={defaultTheme}>{children}</BasisProvider>;
}
export const productGem = 'gem';

export { SoftQuoteLayout };
