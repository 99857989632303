import React from 'react';
import styled from '@emotion/styled';
import { LoadingIcon } from 'basis';

const LoadingContainer = styled.div`
  text-align: center;
`;

const LoadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  h4{
    font-weight: bold;
    color: #000;
    text-align: center;
    margin: 40px 0 5px 0;
    font-size: 24px;
    line-height: 28px;
  }
  p{
    margin: 10px 0 5px 0;
    font-size: 18px;
    line-height: 28px;
  }
`;

const LoadingIconText = props => {
  const { message } = props;
  return (
    <LoadingContainer>
      <LoadingIcon size="medium" />
      <LoadingMessage>
        <h4>Hang tight!</h4>
        <p>{ message ? message : 'Loading in progress'}</p>
      </LoadingMessage>
    </LoadingContainer>
  );
}

export { LoadingIconText };